import {
    ROOM_CREATED,
    ROOM_STATE,
    ROOM_ERROR,
    ROOM_LEAVE,
    ROOM_JOIN,
    ROOM_START_GAME,
    ROOM_RECONNECT,
    ADD_PLAYER,
    REMOVE_PLAYER,
    UPDATE_PLAYER,
    ADD_AVATAR,
    UPDATE_AVATAR,
} from "actions/types";
import LoggingService from "services/logging";

const initialState = {
    isHost: false,
    roomOpen: false,
    roomId: "",
    sessionId: "",
    room: {},
    roomState: {},
    roomError: false,
    errorMessage: "",
    player: null,
    startingGame: false,
    redirectInfo: {},
    players: [],
    avatarList: [],
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case ROOM_CREATED:
            return {
                ...state,
                isHost: true,
                roomOpen: true,
                roomId: payload.room.id,
                sessionId: payload.room.sessionId,
                room: payload.room,
                roomState: payload.state,
                roomError: false,
                errorMessage: "",
                player: null,
            };
        case ROOM_STATE:
            return {
                ...state,
                roomState: payload.roomState,
                isHost: payload.roomState.host.id == state.sessionId,
                player: state.isHost ? {} : payload.roomState.players[state.sessionId],
            };
        case ROOM_ERROR:
            //LoggingService.logError(payload.message, payload.error);
            return {
                ...state,
                isHost: false,
                roomError: true,
                errorMessage: payload.error,
                roomOpen: false,
                room: {},
                roomId: "",
                sessionId: "",
                roomState: {},
                play: null,
                startingGame: false,
                redirectInfo: {},
            };
        case ROOM_LEAVE:
            return {
                ...state,
                isHost: false,
                roomOpen: false,
                room: {},
                roomId: "",
                sessionId: "",
                roomState: {},
                player: null,
                players: []
            };
        case ROOM_JOIN:
            return {
                ...state,
                isHost: false,
                roomOpen: true,
                roomId: payload.room.id,
                sessionId: payload.room.sessionId,
                room: payload.room,
                roomState: payload.state,
                roomError: false,
                errorMessage: "",
                player: payload.state.players[state.sessionId],
            };
        case ROOM_RECONNECT:
            const isHost = payload.room.sessionId == payload.state.host.id
            return {
                ...state,
                isHost: isHost,
                roomOpen: true,
                roomId: payload.room.id,
                sessionId: payload.room.sessionId,
                room: payload.room,
                roomState: payload.state,
                roomError: false,
                errorMessage: "",
                player: isHost ? null : payload.state.players[state.sessionId],
            };
        case ROOM_START_GAME:
            return {
                ...state,
                startingGame: true,
                redirectInfo: {
                    gameURL: payload.gameURL,
                    gameId: payload.gameId,
                    roomId: state.roomId,
                    sessionId: state.sessionId,
                    isHost: state.isHost,
                    reconnectionToken: payload.reconnectionToken,
                },
            };
        case ADD_PLAYER:
            return {
                ...state,
                players: [...state.players, payload.player],
            }
        case REMOVE_PLAYER:
            return {
                ...state,
                players: state.players.filter(x => x.id != payload.player.id),
            }
        case UPDATE_PLAYER:
            let statePlayers = [...state.players];
            let pos = statePlayers.map(function (e) { return e.id; }).indexOf(payload.player.id);

            let statePlayer = { ...state.players[pos] };
            statePlayer[payload.field] = payload.value;
            //payload.changes.forEach(change => {
            //    //console.log(change.field);
            //    //console.log(change.value);
            //    //console.log(change.previousValue);
            //    statePlayer[change.field] = change.value;
            //});
            statePlayers[pos] = statePlayer;
            return {
                ...state,
                players: statePlayers,
            }
        case ADD_AVATAR:
            return {
                ...state,
                avatarList: [...state.avatarList, payload.avatar],
            }
        case UPDATE_AVATAR:
            let stateAvatars = [...state.avatarList];
            let posAvatar = stateAvatars.map(function (e) { return e.id; }).indexOf(payload.avatar.id);
            let stateAvatar = { ...state.avatarList[posAvatar] };
            stateAvatar[payload.field] = payload.value;
            stateAvatars[posAvatar] = stateAvatar;
            return {
                ...state,
                avatarList: stateAvatars,
            }
        default:
            return state;
    }
}
