import React, { Component } from 'react';
import { connect, } from 'react-redux';
import { Toast, } from 'react-bootstrap';
import { withRouter } from '../withRouter';
import { deleteToast } from 'actions/cart';
import styles from './ToastsStyles.module.scss'

// const { items, } = useSelector(state => state.cart);

class Toasts extends Component {
    static displayName = Toasts.name;

    constructor(props) {
        super(props);

        this.state = {
            activeToastsCount: 0,
            scrolledPastHeader: false,
        };
    }

    // Need to key into when items are added to the cart
    // Need a function to dismiss the relevant toast
    // Need to dismiss the toast after x amount of time

    componentDidMount() {
        this.setState({ activeToastsCount: this.props.cart.toastItems.length, });

        window.addEventListener('scroll', this.handleScroll, { passive: true })
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.cart.toastItems.length > this.state.activeToastsCount) {
            const toastItem = this.props.cart.toastItems[this.props.cart.toastItems.length - 1];
            setTimeout(() => {
                this.deleteToast(toastItem.id)
            }, 5000);
        }

        if (this.state.activeToastsCount != this.props.cart.toastItems.length) {
            this.setState({ activeToastsCount: this.props.cart.toastItems.length})
        }
    }

    deleteToast(toastId) {
        this.props.deleteToast(toastId);
    }

    handleScroll = (event) => {
        if (window.scrollY > 100) {
            this.setState({ scrolledPastHeader: true, }) 
        } else {
            this.setState({ scrolledPastHeader: false, }) 
        }
    }

    handlePosition = () => {
        if (this.state.scrolledPastHeader) {
            return 0
        } else {
            // menu height is fixed at 100px
            const pos = 100 - window.scrollY; // px
            return `${pos}px`
        }
    }

    render() {
        return (
            <div className={styles.toastsOverlay} style={{top: this.handlePosition()}}>
                {
                    this.props.cart.toastItems.map((toast) => {
                        return <Toast onClose={() => this.deleteToast(toast.id)}>
                            <Toast.Header>
                                <strong className="me-auto">Basket</strong>
                            </Toast.Header>
                            <Toast.Body>{toast.name} added to basket</Toast.Body>
                            <Toast.Body>
                                <div className={styles.buttonWrap}>
                                    <button className={`${styles.button} ${styles.small} ${styles.alternate}`} onClick={() => this.deleteToast(toast.id)}>Close</button>
                                    <button className={`${styles.button} ${styles.small}`} onClick={() => this.props.navigate("/checkout")}>Checkout</button>
                                </div>
                            </Toast.Body>
                        </Toast>
                    })
                }
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        cart: state.cart,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteToast: (toastId) => {
            dispatch(deleteToast(toastId))
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Toasts));
