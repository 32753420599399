import React from "react";
import { Container, Table } from 'reactstrap';
import styles from './Policy.module.scss';
import PolicyLinks from "components/policy/PolicyLinks";

import ImgJUNE from "images/Promotions/FRI IS BACK.png";
import ImgLUCK from "images/Promotions/LUCK.png";
import ImgSummer from "images/Promotions/Summer.png";

const FriYayGiveaway = () => {

    return (
        <>
        <Container className={styles.policydoc}>
            {/*<PolicyLinks />*/}
            <div>
                <div className="row">
                    <div className="col">
                        <img className={`${styles.policyImgFloat} ${styles.hideOnMobile}`} src={ImgSummer} alt="Summer" />
                        <img className={styles.policyImg} src={ImgJUNE} alt="Fri Yay" />
                        {/*<h1>Jubilant June</h1>*/}
                            <p>Goodbye, sunny BBQs; hello, indoor fun! Don't fret. We have you covered. Game.City, the ultimate online hub for Big Potato Games like; Scrawl, Herd Mentality, Colour Brain and many, many more... is hosting our first SUMMER OF GAMING and to start, we're bringing back Fri-yay giveaways every week until September 1st! So, don't miss the chance to snag some freebies on us.</p>
                        <h3>How to enter:</h3>
                        <ol>
                            <li>Follow Game.City on any of the following social media channels; X (@GameCityColl), Instagram (@the.games.collective), Facebook (@Game City) or TikTok (@game.city.collective).</li>
                            <li>Like the pinned competition post and share it with friends/family.</li>
                        </ol>
                        <h3>Selection:</h3>
                        <p>Winners will be drawn, at random each Friday, from each social channel for the month of June from all available entries since the start of the promotional period.</p>
                        <p>The more social channels you follow, the greater your chances are to win some amazing prizes!.</p>
                        <p>Good Luck!</p>
                        <p>Game.City Team</p>
                        <img className={styles.policyImg} src={ImgLUCK} alt="Good Luck" style={{ maxWidth: "35%" }} />

                        <h2>Game.City ‘Fri-Yay Giveaway’ Competition Terms & Conditions</h2>
                        <ol>
                            <li>The promoter is Game.City whose registered office is at 32 High Street, Guildford, GU1 3EL.</li>
                            <li>Game.City is offering 13 entrants the chance to win prizes at the company’s discretion. No cash or other alternatives will be offered. The prizes are not transferable. Prizes are subject to availability and we reserve the right to substitute any prize with another of equivalent value without giving notice.</li>
                            <li>Competition winners will be chosen at random from all valid entires from the beginning of the promotional period. Decisions are final and no correspondence will be entered into.</li>
                            <li>Game.City will endeavour to notify winners of the competition within 30 days of the closing date set. Return of any prize notification as undeliverable or failure to reply within the period specified in the notification within a reasonable time and by no later than 28 days after the prize has been accepted.</li>
                            <li>All competition prizes are subject to availability.</li>
                            <li>All entrants must be following/subscribed to one of the following Game.City social channels; Twitter, Instagram, Facebook or TikTok. Entrants must like the competition post and share it with friends/family as specified in the competition announcement.</li>
                            <li>The competition is open to entrants aged 18 or over.</li>
                            <li>The competition is open to entrants based in the UK. </li>
                            <li>There is no entry fee and no purchase necessary to enter this competition, however internet access is required.   </li>
                            <li>By entering this competition, an entrant is indicating their agreement to be bound by these terms and conditions.</li>
                            <li>Route to entry for the competition and details of how to enter are via:</li>
                            <li>Closing date for entry will be the 30/08/24 at 23:59 (GMT). After this date no further entries to the competition will be permitted.</li>
                            <li>No responsibility can be accepted for entries not received for whatever reason.</li>
                            <li>Game.City will notify the winners when their prizes are out for delivery.</li>
                            <li>The winners agree to the use of their first name and image of their initial prize delivery in any publicity material, as well as their entry. Any personal data relating to the winners or any other entrants will be used solely in accordance with current UK data protection legislation and will not be disclosed to a third party without the entrant’s prior consent.</li>
                            <li>Game.City decision in respect of all matters to do with the competition will be final and no correspondence will be entered into.</li>
                            <li>Game.City decision as to those able to take part and selection of winners is final. No correspondence relating to the competition will be entered into.</li>
                            <li>Game.City shall have the right, at its sole discretion and at any time, to change or modify these terms and conditions, such change shall be effective immediately upon posting on our socials.</li>
                            <li>The competition and these terms and conditions will be governed by English law and any disputes will be subject to the exclusive jurisdiction of the courts of England.  </li>
                            <li>Game.City reserves the right to cancel or amend the competition and these terms and conditions without notice in the event of a catastrophe, war, civil or military disturbance, act of God or any actual or anticipated breach of any applicable law or regulation or any other event outside of Game.City’s control. Any changes to the competition will be notified to entrants as soon as possible by Game.City.</li>
                            <li>Game.City is not responsible for inaccurate prize details supplied to any entrant by any with this competition.</li>
                            <li>This promotion is in no way sponsored, endorsed or administered by, or associated with, X, Instagram, Facebook, TikTok or any other Social Network. You are providing your information to Game.City and not to any other party. </li>
                        </ol>
                    </div>
                </div>
            </div>
        </Container>
        </>
        );
}

export default FriYayGiveaway;