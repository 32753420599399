import MetaPixel from "react-facebook-pixel";

const options = {
    autoConfig: true,
    debug: true,
};

MetaPixel.init("1143423570408454", null, options);

function metaPageView() {
    MetaPixel.pageView();
}

function metaAddToCart(product, currency, value) {
    MetaPixel.track("AddToCart", {
        content_ids: [ product.productId ],
        content_name: product.name,
        content_type: "product",
        contents: [
            {
                id: product.productId,
                quantity: 1
            }
        ],
        currency,
        value,
    })
}

//function metaRemoveFromCart(product, currency, value) {
//    MetaPixel.trackCustom("RemoveFromCart", {
//        content_ids: [product.productId],
//        content_name: product.name,
//        content_type: "product",
//        contents: [
//            {
//                id: product.productId,
//                quantity: 1
//            }
//        ],
//        currency,
//        value,
//    })
//}

function metaBeginCheckout(products, currency, value) {
    let content_ids = [];
    let contents = [];
    let num_items = 0;
    products.forEach((product) => {
        content_ids.push(product.productId);
        contents.push({
            id: product.productId,
            quantity: product.quantity,
        });
        num_items += product.quantity;
    });

    MetaPixel.track("InitiateCheckout", {
        content_category: "Product List",
        content_ids,
        contents,
        currency,
        value,
        num_items,
    });
}

function metaPurchaseComplete(products, currency, value, paymentIntent) {
    let content_ids = [];
    let contents = [];
    let num_items = 0;
    products.forEach((product) => {
        content_ids.push(product.productId);
        contents.push({
            id: product.productId,
            quantity: product.quantity,
        });
        num_items += product.quantity;
    });

    MetaPixel.track("Purchase", {
        content_name: paymentIntent,
        content_ids,
        content_type: "product",
        contents,
        currency,
        value,
        num_items,
    });
}

function metaSignUp() {
    MetaPixel.track("CompleteRegistration");
}

export default {
    metaPageView,
    metaAddToCart,
    metaBeginCheckout,
    metaPurchaseComplete,
    metaSignUp,
}