import {
    UPDATE_CART,
    UPDATE_TOASTS
} from "actions/types";


const initialState = {
    items: [],
    toastItems: [],
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case UPDATE_CART:
            return {
                items: payload.items,
                toastItems: payload.toastItems
            };
        case UPDATE_TOASTS:
            return {
                items: state.items,
                toastItems: payload.toastItems,
            }
        default:
            return state;
    }
}



