import TikTokPixel from "tiktok-pixel";

const options = {
    debug: true,
};

TikTokPixel.init("C3R97H3VT760H1C1ETTG", null, options);

function tiktokPageView() {
    TikTokPixel.pageView();
}

function tiktokAddToCart(product, currency, value) {
    TikTokPixel.track("AddToCart", {
        content_id: product.productId,
        content_type: "product",
        quantity: 1,
        description: product.description,
        currency,
        value,
    });
}

function tiktokBeginCheckout(products, currency, value) {
    let contents = [];
    products.forEach((product) => {
        contents.push({
            content_id: product.productId,
            quantity: product.quantity,
            content_name: product.name,
        });
    });

    TikTokPixel.track("InitiateCheckout", {
        content_type: "product",
        contents,
        currency,
        value,
    });
}

function tiktokPurchaseComplete(products, currency, value, paymentIntent) {
    let contents = [];
    let quantity = 0;
    products.forEach((product) => {
        contents.push({
            content_id: product.productId,
            quantity: product.quantity,
            content_name: product.name,
        });
        quantity += product.quantity;
    });
    
    TikTokPixel.track("CompletePayment", {
        content_type: "product",
        content_id: paymentIntent,
        quantity,
        description: "Finished Game.City Purchase",
        contents,
        currency,
        value,
    });
}

function tiktokSignUp() {
    TikTokPixel.track("CompleteRegistration");
}

export default {
    tiktokPageView,
    tiktokAddToCart,
    tiktokBeginCheckout,
    tiktokPurchaseComplete,
    tiktokSignUp,
}