import React, { Component } from 'react';
import { connect, } from 'react-redux';
import {  Col, } from 'react-bootstrap';
import Lottie from "react-lottie";
import { withRouter } from '../withRouter';
import getAvatarById from '../../constants/avatars';
import styles from 'components/Room/Player.module.scss';
import { IoCloseCircleSharp } from "react-icons/io5"

class Player extends Component {
    static displayName = Player.name;

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    componentDidMount() {

    }

    removePlayer = (id) => {
        this.props.room.room.send("remove_player", { id: this.props.player.id });
    }

    render() {

        return (
            this.props.player &&
            <Col xs={3} className={`${styles.player}`} style={{ height: "115px !important" }}>
                <div className={`${styles.potato} ${!this.props.player.connected && styles.fade}`}>
                    <Lottie
                        options={getAvatarById(this.props.player.avatar).idleAnim}
                        width="100%"
                        height="100%"
                        isClickToPauseDisabled={true}
                    />
                </div>
                <p className={`${styles.name} ${this.props.player.name.length >= 6 ? this.props.player.name.length >= 9 ? styles.smaller : styles.small : ""} ${!this.props.player.connected && styles.fade}`}>{this.props.player.name}</p>
                {
                    !this.props.player.connected &&
                    <div className={styles.countdownContainer}>
                        <h2 className="m-0">{this.props.player.connectingTimer}</h2>
                        <p>Disconnecting!</p>
                    </div>
                }
                <IoCloseCircleSharp className={styles.removeButton} onClick={this.removePlayer} />
            </Col>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        room: state.room,
    };
}

export default connect(mapStateToProps, null)(withRouter(Player));
