import React, { Component } from 'react';
import { connect, } from 'react-redux';
import { Container, } from 'react-bootstrap';
import { withRouter } from './withRouter';

import Game from "./Games/Game";
import GamePack from "./Games/GamePack";
import GameAddon from './Games/GameAddon';
import GameList from "constants/GameList";
import GamePackList from "constants/GamePackList";
import GameAddonList from 'constants/GameAddonList';
import styles from './ShopStyles.module.scss'

const categories = {
    all: 'all products',
    individual: 'single games',
    packs: 'game packs',
    addons: 'add-ons',
};

class Shop extends Component {
    static displayName = Shop.name;

    constructor(props) {
        super(props);

        this.state = {
            filter: categories.all,
        };
    }

    componentDidMount() {
        // const queryParams = new URLSearchParams(window.location.search);
        // console.log(queryParams);
        // if (queryParams.has("roomId") && queryParams.has("sessionId")) {
        //     console.log("has params");
        //     setTimeout(() => this.props.navigate(`/join/?roomId=${queryParams.get("roomId")}&sessionId=${queryParams.get("sessionId")}`));
        // }
    }

    setFilter(value) {
        this.setState({ filter: value })
    }

    isDev() { return !process.env.REACT_APP_ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT === 'development' }

    render() {
        return (
            <div className={styles.pageShop}>
                <section className={`${styles.heroBackground} ${styles.band}`}>
                    <Container className="text-center">
                        <h1>Our Games</h1>
                    </Container>
                </section>

                <section className={styles.filterSection}>
                    <Container className="text-center">
                        <ul className={styles.filterBar}>
                            {Object.entries(categories).map(([key, value]) => {
                                return <li onClick={() => this.setFilter(value)} className={(this.state.filter === value && styles.active)}>
                                    {value}
                                </li>
                            })}
                        </ul>
                    </Container>
                </section>

                <section className={`${styles.gameListingSection} ${styles.band}`}>
                    <Container>
                        {
                            [categories.packs, categories.all].includes(this.state.filter) &&
                            <>
                                <h2 className='mb-1'>{categories.packs.toUpperCase()}</h2>
                                <div className='row justify-content-center'>
                                {GamePackList.filter(g => g.enabled || this.isDev()).map(g => <div className='col-md-6 col-lg-4 text-center'><GamePack game={g} /></div>)}
                                </div>
                            </>
                        }
                        {
                            [categories.individual, categories.all].includes(this.state.filter) &&
                            <>
                                <h2 className='mb-1'>{categories.individual.toUpperCase()}</h2>
                                <div className='row justify-content-center'>
                                    {GameList.filter(g => g.enabled || this.isDev()).map(g => <div className='col-md-6 col-lg-4 text-center'><Game game={g} /></div>)}
                                </div>
                            </>
                        }
                        {
                            [categories.addons, categories.all].includes(this.state.filter) &&
                            <>
                                <h2 className='mb-1'>{categories.addons.toUpperCase()}</h2>
                            <div className='row justify-content-center'>
                                    {GameAddonList.filter(a => a.enabled || this.isDev()).map(a => <div className='col-md-6 col-lg-4 text-center'><GameAddon addon={a} /></div>) }
                                </div>
                            </>
                        }
                    </Container>
                </section>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
}

export default connect(mapStateToProps, null)(withRouter(Shop));
