import {
    SET_DISCOUNT,
} from "actions/types";


const initialState = {
    discountCode: null,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_DISCOUNT:
            return {
                ...state,
                discountCode: payload.discountCode,
            };
        default:
            return state;
    }
}
