import React, { Component } from 'react';
import styles from '../Forms/FormStyles.module.scss'

class FormPage extends Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (
            <div className={!this.props.modal && styles.backgroundWrap}>
                <div className={`${styles.formWrap} text-center`}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default FormPage;
