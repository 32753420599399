import React, { Component } from 'react';
import { connect, } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { withRouter } from '../withRouter';
import { addToCart } from 'actions/cart';
import styles from './GameStyles.module.scss'
import GameAddonList from '../../constants/GameAddonList';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'

class Game extends Component {
    static displayName = Game.name;

    constructor(props) {
        super(props);

        this.state = {
            game: null,
            gameAddons: this.getAddons(),
        };
    }

    getAddons() {
        return GameAddonList.filter((a) => a.forGameId === this.props.game.gameId).map((a) => {
            const owned = a.price === 0 || (this.props.addons && this.props.addons.find(owendAddon => owendAddon.code === a.addonId) ? true : false);
            return {...a, owned}
        });
    }

    componentDidMount() {
    }

    tryGoToGame(gameId) {
        this.props.room.room.send("StartGame", { gameId });
    }

    clickAddToCart(game) {
        this.props.addToCart(game);
    }

    ownsGame() {
        return this.props.owned;
    }

    getCardStyling() {
        // Bad name of method, this is specific to card background
        return {
            backgroundImage: `url(${this.props.game.meta.cardBackground})`
        }
    }

    getCardPriceBannerStyling() {
        return {
            background: this.props.game.meta.cardPriceColour
        }
    }

    getButtonStyling(opacity = 1) {
        return {
            background: this.props.game.meta.storeAccentColour
        }
    }

    clickGameCard = (e) => {
        e.preventDefault();

        if (this.props.fromLobby) {
            this.props.loadGameMenu(this.props.game);
        } else {
            this.props.navigate(this.props.game.storeSlug)
        }
    }

    getPrice() {
        let countryCode = this.props.pricing.countryCode;

        if (countryCode === "GB") {
            return this.props.game?.pricing.gb.display;
        } else {
            return this.props.game?.pricing.us.display;
        }
    }

    goToAddon(g) {
        if (g.owned) this.props.loadGameMenu(this.props.game);
        else this.props.navigate(g.storeSlug);
    }
    getAddonText() {
        const ownedCount = this.state.gameAddons.filter(a => a.owned).length;
        const availableCount = this.state.gameAddons.length - ownedCount;
        let text = "Add-ons: "
        text += ownedCount !== 0 ? `${ownedCount} owned` : "";
        if (ownedCount > 0 && availableCount > 0) text +=", "
        text += availableCount !== 0 ? `${availableCount} available` : "";
        return text;
    }

    renderAddonList() {
        const addons = this.state.gameAddons.map((a) => {
            return <div className={styles.addonCard} >
                {/*{a.owned && <FontAwesomeIcon icon={faCircleCheck} target="_blank" />}*/}
                    <img className={`${a.owned && styles.owned}`} src={a.meta.tinyCard} onClick={() => this.goToAddon(a)} alt="addon-card" />
                </div> 
        });
        if (addons.length > 0) {
            return <>
                <div className={styles.addonList} style={this.getButtonStyling()}>
                    {addons}
                </div>
            </>
        } else {
            return null;
        }
    }

    render() {
        return (
            this.props.game &&
            <div className={`${styles.gameCardWrapper} ${this.props.fromLobby && styles.lobby} ${this.state.gameAddons.length > 0 && styles.hasAddons} ${!this.props.game.enabled && styles.hidden}`}>
                    {this.props.fromLobby && this.renderAddonList()}
                    <div className={styles.gameCard}>
                    {
                        this.props.gameVotes > 0 &&
                        <div className={styles.votesCounter} style={this.getCardPriceBannerStyling()}><span>{this.props.gameVotes}</span></div>
                    }
                    <div className={`${styles.gameCardBackground} ${this.props.fromLobby && styles.rounded}`} style={this.getCardStyling()}>
                        <img src={this.props.game.meta.cardLogo} className={`${styles.gameCardLogo} ${this.props.game.meta.cardLogo2 && styles.hasSecond}`} alt={`${this.props.game.name} logo`}></img>
                        {this.props.game.meta.cardLogo2 && <img src={this.props.game.meta.cardLogo2} className={styles.gameCardLogo2} alt={`${this.props.game.name} logo`}></img>}
                        {
                            !this.props.fromLobby &&
                            <div className={styles.priceBanner} style={this.getCardPriceBannerStyling()}><span>{this.getPrice() || <Spinner animation="border" size="sm" />}</span></div>
                        }
                        <div onClick={this.clickGameCard} className={styles.clickOverlay} />
                        {
                            this.props.game.gameInfo.banner &&
                            <div className={styles.banner} style={this.getButtonStyling()}>{this.props.game.gameInfo.banner}</div>
                        }
                        {this.props.fromLobby && this.state.gameAddons.length > 0 &&
                                <div className={styles.availableAddons} style={this.getButtonStyling()}>{this.getAddonText()}</div>
                        }
                    </div>
                    {
                        !this.props.fromLobby &&
                        <React.Fragment>
                            <div className={`${styles.cardDescriptionBox} p-3`}>
                                <h4>{this.props.game.name}</h4>
                                <p>{this.props.game.description}</p>
                            </div>
                                    <button onClick={() => this.clickAddToCart(this.props.game)} className={`${styles.addToCartBox} p-3`}>
                                <h4>Add To Cart</h4>
                            </button>
                        </React.Fragment>
                    }
                    </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        room: state.room,
        pricing: state.pricing,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        addToCart: (game) => {
            dispatch(addToCart(game))
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Game));
