import React, { Component } from 'react';
import Lottie from 'react-lottie';

import potatosAtWork from '../animations/potatosAtWork.js';

class Maintenance extends Component {
    static displayName = Maintenance.name;

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (
            <div className={`d-flex justify-content-center align-items-center`} style={{ backgroundColor: "black", width: "100vw", height: "100vh" }}>
                <div style={{ width: "auto", height: "75%" }}>
                    <Lottie
                        options={potatosAtWork}
                        isClickToPauseDisabled={true}
                    />
                </div>
            </div>
        );
    }
}


export default Maintenance;
