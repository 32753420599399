//import { ServerEvent, EventRequest, UserData, CustomData, } from "facebook-nodejs-business-sdk";
import stripeService from "services/stripe.service";

const accessToken = "EAAEjmafghgIBO166RPHxu4RehYMqGuncVipYs8YiKTk4K66fYwwAGbHGH337VpQiifof4MtAYQHWOLQ55jn2WZB40gfbD901UL6ZBNuPaRMjEvvuNmOlZAG3FHFqSaF1cy3vqL1PchCwR0eXu6xa2wFHSKiE1e3TBwNbuQJ3AdVct2llaPp5zbpR10AZBZAfuugZDZD";
const pixelId = "1143423570408454";

// Function to send events to Facebook Conversions API
async function sendEvent(eventName, eventData) {
    try {

        const apiUrl = `https://graph.facebook.com/v14.0/${pixelId}/events?access_token=${accessToken}`;
        let res = await stripeService.getIP();
        const ip = res.data;
        const userAgent = navigator.userAgent;

        // duplication issue?
        //window.fbq('track', eventName, {
        //    ...eventData,
        //    user_data: {
        //        client_ip_address: ip,
        //        client_user_agent: userAgent,
        //    }
        //});

        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                data: [
                    {
                        event_name: eventName,
                        event_time: Math.floor(new Date() / 1000),
                        user_data: {
                            client_ip_address: ip, // Replace with the user's IP address
                            client_user_agent: userAgent,
                        },
                        custom_data: eventData,
                        event_source_url: window.location.href,
                        action_source: 'website',
                    },
                ],
                //test_event_code: 'TEST62915', // Optional, remove this when going live
            }),
        });

        const result = await response.json();
        console.log(`Event ${eventName} sent successfully. Response:`, result);
    } catch (error) {
        console.error(`Error sending event ${eventName}:`, error);
    }
};


export default {
    sendEvent,
}
