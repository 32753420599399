import React from "react";
import { Container } from 'reactstrap';
import styles from './Policy.module.scss';

const Application = () => {
    return(
        <Container className={styles.policydoc}>
            <div>
                <h4>Thank you for reviewing our application. </h4>
                <p>The Games Collective has enjoyed a fantastic year and the support from Microsoft has been an essential component of that. Our revenue has grown by over 400%  in 2023 with a real traction in the market and high growth in Q4.  This has seen our consumption of resources increasing.
                    ‍
                    We have launched four new products and have secured a major collaboration with a significant entertainment company to bring more IP based games to the platform in 2024. This includes planned development with OpenAI.
                    We are continuing to develop our portfolio of products and looking to expand into other regions and languages.
                </p>
                <br/>
                <p>This will allow us to scale.</p>
                <br />
                <p>Continued support from Microsoft will make a huge difference to our business growth and we hope that you will be able to continue to support us with Azure credits. </p>
                <br />
                <p>Thank you again,</p>
                <p>The Games Collective.</p>
            </div>
        </Container>

    );
}

export default Application;