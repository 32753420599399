import ReactGA from "react-ga4";

ReactGA.initialize("G-B4M5S8LVN6");

function ga4PageView(path) {
    ReactGA.send({ hitType: "pageview", page: path, });
}

function ga4AddToCart(product, currency, value) {
    ReactGA.event("add_to_cart", {
        currency,
        value,
        items: [
            {
                item_id: product.productId,
                item_name: product.name
            }
        ]
    });
}

function ga4RemoveFromCart(product, currency, value, quantity) {
    ReactGA.event("remove_from_cart", {
        currency,
        value,
        items: [
            {
                item_id: product.productId,
                item_name: product.name,
                quantity
            }
        ]
    });
}

function ga4BeginCheckout(products, currency, value, coupon = null) {
    let items = [];
    products.forEach((product) => {
        items.push({
            item_id: product.productId,
            item_name: product.name,
            quantity: product.quantity,
        });
    });

    ReactGA.event("begin_checkout", {
        currency,
        value,
        items,
        coupon,
    })
}

function ga4PurchaseComplete(products, currency, value, paymentIntent, coupon = null) {
    let items = [];
    products.forEach((product) => {
        items.push({
            item_id: product.productId,
            item_name: product.name,
            quantity: product.quantity,
        });
    });

    ReactGA.event("purchase", {
        transaction_id: paymentIntent,
        currency,
        value,
        items,
        coupon,
    })
}

function ga4Login() {
    ReactGA.event("login");
}

function ga4SignUp() {
    ReactGA.event("sign_up");
}

export default {
    ga4PageView,
    ga4AddToCart,
    ga4RemoveFromCart,
    ga4BeginCheckout,
    ga4PurchaseComplete,
    ga4Login,
    ga4SignUp
}