import {
    SET_DISCOUNT
} from "actions/types";

export const setDiscount = (discountCode) => (dispatch) => {
    dispatch({
        type: SET_DISCOUNT,
        payload: {
            discountCode,
        },
    });
};

