// Scrawl Adult
import ScrawlLogo from "images/games/scrawl-adult.png";
import ScrawlStoreBackground from "images/storeBackgrounds/scrawl-green.png";
import ScrawlStoreLogo from "images/storeLogos/scrawl-adult.png";
import ScrawlCardLogo from "images/gameCards/logo/scrawl-adult.png";
import ScrawlCardBackground from "images/gameCards/background/scrawl-green.png";
import ScrawlMobilePreview1 from "images/gameStorePreviews/scrawl/scrawl-mobile-1.png";
import ScrawlMobilePreview2 from "images/gameStorePreviews/scrawl/scrawl-mobile-2.png";
import ScrawlDesktopPreview1 from "images/gameStorePreviews/scrawl/scrawl-tv-ls.png";
import ScrawlDesktopPreview2 from "images/gameStorePreviews/scrawl/scrawl-desktop-2.png";
import ScrawlTinyCard from "images/tinyCards/scrawl-adult.png";

// Colour Brain Junior
import ColourBrainLogo from "images/games/colour-brain-junior.png";
import ColourBrainStoreBackground from "images/storeBackgrounds/colour-brain-junior.png";
import ColourBrainStoreLogo from "images/storeLogos/colourBrainJuniorLogo.png";
import ColourBrainCardLogo from "images/gameCards/logo/colourBrainJuniorLogo.png";
import ColourBrainCardBackground from "images/gameCards/background/colour-brain-junior.png";
import ColourBrainMobilePreview1 from "images/gameStorePreviews/colourBrain/colourbrain-mobile-1.png";
import ColourBrainMobilePreview2 from "images/gameStorePreviews/colourBrain/colourbrain-mobile-2.png";
import ColourBrainDesktopPreview1 from "images/gameStorePreviews/colourBrain/colourbrain-desktop-1.png";
import ColourBrainDesktopPreview2 from "images/gameStorePreviews/colourBrain/colourbrain-desktop-2.png";
import ColourBrainTinyCard from "images/tinyCards/colour-brain-junior.png";



const GameAddonList = [
    {
        type: "Addon",
        name: "Scrawl Adult",
        pack: 1,
        addonId: "scrawl_adult",
        forGameId: "scrawl",
        enabled: true,
        logo: ScrawlLogo,
        description: "For those with less innocent minds, an optional 17+ game mode!",
        productId: process.env.REACT_APP_SCRAWL_ADULT_PID,
        price: 2.99,
        storeSlug: '/shop/addons/scrawl-adult',
        gameInfo: {
            minAge: 17,
            minPlayers: 3,
            avgRoundLength: 600,
            avgRoundLengthText: '10 Mins',
            descriptions: [
                {
                    header: "WARPED MINDS WELCOME",
                    body: "We've heard what some of you have been doing on Scrawl, and we thought sure. Why not? For those with less innocent minds, an optional 17+ game mode is now available!"
                }
            ],
            //banner: "Requires Scrawl",
        },
        upsellIds: ["colour_brain_junior", "maths_master", "word_wheel"],
        meta: {
            cardLogo: ScrawlCardLogo,
            cardBackground: ScrawlCardBackground,
            cardPriceColour: '#EC0080',
            storeBackground: ScrawlStoreBackground,
            storeBackgroundColour: '#FFD900',
            storeInfoBackground: '#FFFFFF',
            storeLogo: ScrawlStoreLogo,
            storeAccentColour: '#212B3D',
            gameMenuTextColour: '#ffffff',
            gameMenuBackgroundColour: '#EA085E',
            previewImages: {
                mobile1: ScrawlMobilePreview1,
                mobile2: ScrawlMobilePreview2,
                desktop1: ScrawlDesktopPreview1,
                desktop2: ScrawlDesktopPreview2,
            },
            tinyCard: ScrawlTinyCard,
        },
        pricing: {
            gb: {
                raw: 2.99,
                display: '\u00A32.99',
                currency: "\u00A3"
            },
            us: {
                raw: 4.99,
                display: "\u00244.99",
                currency: "\u0024"
            }
        }
    },
    {
        type: "Addon",
        name: "Colour Brain Junior",
        pack: 1,
        addonId: "colour_brain_junior",
        forGameId: "colour_brain",
        enabled: true,
        logo: ColourBrainLogo,
        description: "Questions based on things kids should know but adults might struggle with!",
        //productId: process.env.REACT_APP_SCRAWL_ADULT_PID,
        price: 0,
        storeSlug: '/shop/addons/colour-brain-junior',
        gameInfo: {
            minAge: 6,
            minPlayers: 2,
            avgRoundLengthText: '20 mins',
            descriptions: [
                {
                    header: "KIDS KNOW BEST",
                    body: "All the questions are based on things kids should know but adults might struggle with, such as, 'What colour are Strawberry Millions?'"
                }
            ],
            //banner: "Requires Scrawl",
        },
        upsellIds: ["scrawl_adult", "maths_master", "word_wheel"],
        meta: {
            cardLogo: ColourBrainCardLogo,
            cardBackground: ColourBrainCardBackground,
            cardPriceColour: '#10232B',
            storeBackground: ColourBrainStoreBackground,
            storeBackgroundColour: '#FFFFFF',
            storeInfoBackground: '#FFFFFF',
            storeLogo: ColourBrainStoreLogo,
            storeAccentColour: '#93A2AA',
            gameMenuTextColour: '#000000',
            gameMenuBackgroundColour: '#ffffff',
            previewImages: {
                mobile1: ColourBrainMobilePreview1,
                mobile2: ColourBrainMobilePreview2,
                desktop1: ColourBrainDesktopPreview1,
                desktop2: ColourBrainDesktopPreview2,
            },
            tinyCard: ColourBrainTinyCard,
        },
        pricing: {
            gb: {
                raw: 0,
                display: 'FREE',
                currency: "\u00A3"
            },
            us: {
                raw: 0,
                display: "FREE",
                currency: "\u0024"
            }
        }
    },
];

export default GameAddonList;
