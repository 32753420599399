import React, { Component } from 'react';
import { connect, } from 'react-redux';
import { withRouter } from '../withRouter';
import { addToCart } from 'actions/cart';
import styles from './GameVoteStyles.module.scss'

class GameVote extends Component {
    static displayName = GameVote.name;

    constructor(props) {
        super(props);

        this.state = {
            game: null,
        };
    }

    componentDidMount() {

    }

    getCardStyling() {

        // Bad name of method, this is specific to card background
        return {
            backgroundImage: `url(${this.props.game?.meta.cardBackground})`
        }
    }

    getCardPriceBannerStyling() {
        return {
            background: this.props.game?.meta.cardPriceColour
        }
    }

    getButtonStyling() {
        return {
            background: this.props.game?.meta.cardPriceColour
        }
    }

    render() {
        let gameVotes = 0;
        if (this.props.game) gameVotes = this.props.room.players.filter((player) => player.gameVote == this.props.game.gameId).length;
        
        return (
            this.props.game &&
            <div className={`${styles.gameVoteCard} ${this.props.selected && styles.selected}`} style={this.getCardStyling()} onClick={() => this.props.clickEvent(this.props.game)}>
                {
                    this.props.showVotes && gameVotes > 0 &&
                    <div className={styles.votesCounter} style={this.getCardPriceBannerStyling()}><span>{gameVotes}</span></div>
                }
                <img src={this.props.game?.meta.cardLogo} className={styles.gameCardLogo} alt={`${this.props.game?.name} logo`}></img>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        room: state.room,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        addToCart: (game) => {
            dispatch(addToCart(game))
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GameVote));
