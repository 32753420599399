import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { logout } from "actions/auth";
import Analytics from "analytics";

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const AuthVerify = (props) => {
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        console.log('Location changed!', location.pathname);
        Analytics.pageView(location.pathname);

        const user = JSON.parse(localStorage.getItem("user"));
        if (user) {
            const decodedJwt = parseJwt(user.token);
            if (decodedJwt.exp * 1000 < Date.now()) {
               console.log("Logging Out...");
               dispatch(logout());
            }
        }
    }, [location]);

  return <></>;
};

export default AuthVerify;
