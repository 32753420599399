import React from "react";
import { Container } from 'reactstrap';
import styles from './Policy.module.scss';

import PolicyLinks from "components/policy/PolicyLinks";

const DataProtection = () => {
    return(
        <Container className={styles.policydoc}>
            <PolicyLinks />
            <div>
                <h1>The Games Collective Is Committed To Protecting The Privacy Of Your Data</h1>
                <ul>
                    <li>The Games Collective Ltd is registered as a data controller with the Information Commissioners Office.</li>

                    <li>The Games Collective Ltd’s data resides with Microsoft Azure, a cloud based data centre. Azure leads the industry with the most comprehensive compliance coverage, enabling The Games Collective to meet all of our regulatory obligations giving our clients peace of mind that their data is safe and secure.</li>

                    <li>All of your data is stored on secure servers and back-ups are made in real time.</li>

                    <li>The Games Collective and Microsoft Azure actively protect your data.</li>

                    <li>The Games Collective use TLS technology to ensure secure connections so only you can see your Information</li>

                    <li>All passwords are encrypted and access to <a href="https://game.city">https://game.city</a> can only be given by the subscriber.</li>
                </ul>
            </div>
            </Container>
    );
}

export default DataProtection;