import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";

export default function ForwardingURL() {

    const navigate = useNavigate();

    useEffect(() => {
        window.location = "/?discountCode=BigScreenGamesQR50";

    }, []);


    return (
        <></>
    );
}