import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_GAMES,
} from "actions/types";
import { leaveRoom, } from "actions/room";
import AuthService from "services/auth";
import Store from "store/reduxstore";
import Analytics from "analytics";

export const register = (email, password, country) => (dispatch) => {
    return AuthService.register(email, password, country).then(
        (response) => {
            if (response.status == "Error") {
                const message =
                    response.message ||
                    response.message.toString();

                let list = null;
                if (response.messageList) {
                    list = response.messageList
                }

                return Promise.reject(message);
            }
            else {
                dispatch({
                    type: REGISTER_SUCCESS,
                });

                return response;
            }
        },
        (error) => {
            const messageError =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: REGISTER_FAIL,
            });

            return Promise.reject(messageError);
        }
    );
};

export const login = (email, password) => (dispatch) => {
    console.log("do login");
    return AuthService.login(email, password).then(
        (response) => {
            if (response.isAuthenticated == true) {
                Analytics.login();

                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: { user: response },
                });
                //dispatch(getPackOwnership());

                const reduxStore = Store.getState();
                if (reduxStore.room.roomOpen) {
                    reduxStore.room.room.send("update_auth", { token: response.token });
                }

                return Promise.resolve();
            }
            else {
                const messageRes =
                    response.message ||
                    response.message.toString();
                console.log(messageRes);

                dispatch({
                    type: LOGIN_FAIL,
                });

                return Promise.reject(messageRes);
            }
        },
        (error) => {
            const messageError =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            console.log(messageError);

            dispatch({
                type: LOGIN_FAIL,
            });

            return Promise.reject();
        }
    );
};

export const logout = () => (dispatch) => {
    AuthService.logout();

    const reduxStore = Store.getState();
    if (reduxStore.room.roomOpen) {
        dispatch(leaveRoom());
    }

    dispatch({
        type: LOGOUT,
    });

    //history.push('/');
};

export const setGames = (games) => (dispatch) => {
    const reduxStore = Store.getState();
    const updatedUser = { ...reduxStore.auth.user, games}
    dispatch({
        type: SET_GAMES,
        payload: updatedUser
    });

    //history.push('/');
};