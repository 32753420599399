import React, { Component } from 'react';
import { connect, } from 'react-redux';
import {  Spinner, } from 'react-bootstrap';
import { withRouter } from '../withRouter';
import { addToCart } from 'actions/cart';
import styles from './GamePackStyles.module.scss';
import GameList from 'constants/GameList';

class GamePack extends Component {
    static displayName = GamePack.name;

    constructor(props) {
        super(props);

        this.state = {
            game: null,
        };
    }

    componentDidMount() {

    }

    tryGoToGame(gameId) {
        this.props.room.room.send("StartGame", { gameId });
    }

    clickAddToCart(game) {
        this.props.addToCart(game);
    }

    ownsGame() {
        return this.props.owned;
    }

    goToOrbuy(game) {
        if (this.props.owned && this.props.room.roomOpen) {
            this.tryGoToGame(game.gameId)
            console.log('can play')
        } 
        // else {
        //     this.clickAddToCart(game)
        //     console.log('can buy')
        // }
    }

    getCardStyling() {

        // Bad name of method, this is specific to card background
        return {
            backgroundImage: `url(${this.props.game.meta.cardBackground})`
        }
    }

    getCardPriceBannerStyling() {
        return {
            background: this.props.game.meta.cardPriceColour
        }
    }

    getButtonStyling() {
        return {
            background: this.props.game.meta.cardPriceColour
        }
    }

    getPrice() {
        let countryCode = this.props.pricing.countryCode;

        if (countryCode == "GB") {
            return this.props.game?.pricing.gb.display;
        } else {
            return this.props.game?.pricing.us.display;
        }
    }

    getIncludedGames = () => {
        let includedGames = GameList.filter((x) => this.props.game?.gamePackInfo.gamesIncluded.find((y) => y.gameId === x.gameId));

        return includedGames
    }

    getStrikethroughPrice = () => {
        let games = this.getIncludedGames();
        let price = 0;
        let currency;
        let currencyCode = this.props.pricing.countryCode;
        games.forEach((g) => {
            let gamePrice = currencyCode == "GB" ? g.pricing.gb : g.pricing.us;
            if (gamePrice) {
                price += gamePrice.raw;
                currency = gamePrice.display.slice(0, 1);
            }
        });
        if (price === 0) {
            return null;
        }
        return currency + price;
    }

    render() {
        return (
            <div className={`${styles.gameCardWrapper} ${!this.props.game.enabled && styles.hidden}`}>
                <div className={styles.gameCardBackground} style={this.getCardStyling()}>
                    <h2 className={styles.title}><span>{this.props.game.label ? `${this.props.game.label} - `:``}{this.getPrice()}</span></h2>
                    <img src={this.props.game.meta.cardLogo} className={styles.gameCardLogo} alt={`${this.props.game.name} logo`}></img>
                    <div className={styles.priceBanner} style={this.getCardPriceBannerStyling()}><span>{this.getStrikethroughPrice() || <Spinner animation="border" size="sm" />}</span></div>
                    {/*<div className={styles.label} style={this.getCardPriceBannerStyling()}><span>{this.props.game.label}</span></div>*/}

                    {/*<div className={styles.gameCardOverlay}>*/}
                    {/*    <p><strong>{this.props.game.description}</strong></p>*/}
                    {/*    <p className={styles.gamesIncludedHeader}>Games included in this pack</p>*/}
                    {/*    <div className={styles.includedGamesWrapper}>*/}
                            
                    {/*        {this.props.game.gamePackInfo.gamesIncluded.map(g => {*/}
                    {/*            return <p>{g.name}</p>*/}
                    {/*        })}*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div onClick={() => this.props.navigate(this.props.game.storeSlug)} className={styles.clickOverlay} />
                    <div className={`${styles.cardFooter}`}>
                        <h4 style={{margin: "unset"}}>Games Included</h4>
                        <div className={styles.gamesList}>
                            {
                                this.props.game.gamePackInfo.gamesIncluded.map((g) => {
                                    return <img src={g.tinyCard} alt={`${g.name}`} className={styles.tinyCard} />
                                })
                            }
                        </div>
                        {
                            !this.props.fromLobby &&
                            <button className={`${styles.addToCart} p-3`} onClick={() => this.clickAddToCart(this.props.game)}>
                                <h4>Add To Cart</h4>
                            </button>
                        }
                    </div>
                   
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        room: state.room,
        pricing: state.pricing,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        addToCart: (game) => {
            dispatch(addToCart(game))
        }
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GamePack));
