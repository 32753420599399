import React, { Component } from 'react';
import { withRouter } from '../withRouter';

import styles from 'components/Room/LobbyStyles.module.scss';
import formStyles from "components/Forms/FormStyles.module.scss";

import FormPage from '../Forms/FormPage';

class ErrorModal extends Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (
            <div className={`${styles.screenOverlay} ${this.props.styles}`} >
                <div className={styles.screenOverlayBG}></div>
                <FormPage modal={true}>
                    <h2 className={formStyles.formTitle}>{this.props.title}</h2>
                    <p>{this.props.message}</p>
                    <button onClick={() => this.props.callback()} className={`${styles.button} ${styles.alternate}`} style={{ margin:"0 10px" }}>{this.props.callbackText}</button>
                    {this.props.callbackText2 && <button onClick={() => this.props.callback2()} className={`${styles.button}`} style={{ margin: "0 10px" }}>{this.props.callbackText2}</button>}
                </FormPage>
            </div>
        );
    }
}


export default withRouter(ErrorModal);
