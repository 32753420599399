import lifestyle1 from "../images/lifestyles/lifestyle-1.png";
import lifestyle2 from "../images/lifestyles/lifestyle-2.png";
import lifestyle3 from "../images/lifestyles/lifestyle-3.png";
import canofsquirms from "../images/heros/can-of-squirms.jpg";
import colourbrain from "../images/heros/colour-brain.jpg";
import drawwithdave from "../images/heros/draw-with-dave.jpg";
import herdmentality from "../images/heros/herd-mentality.jpg";
import scrawl from "../images/heros/scrawl.jpg";
import thechameleon from "../images/heros/the-chameleon.jpg";
import pack1tatos from "../images/gamePackCards/pack1-tatos.png";
import pack2tatos from "../images/gamePackCards/pack2-tatos.png";
import pack1bg from "../images/gamePackCards/background/pack-1-bg.png";
import pack2bg from "../images/gamePackCards/background/pack-2-bg.png";
import ChameleonLogo from "../images/games/ChameleonLogo.png";
import ColourBrainLogo from "../images/games/ColourBrainLogo.png";
import DrawWithLogo from "../images/games/DrawWithLogo.png";
import HerdLogo from "../images/games/HerdLogo.png";
import PubQuizLogo from "../images/games/PubQuizLogo.png";
import ScrawlLogo from "../images/games/ScrawlLogo.png";
import SquirmsLogo from "../images/games/SquirmsLogo.png";
import woodBg from "images/woodBg.png";
import yellowBg from "images/yellowBg.png";
import yellowBgv2 from "images/yellowBg-v2.png";
import WelcomeGameCityText from "images/WelcomeGameCityText.png";
import hourglass from "images/hourglass.png";
import canOfSquirmsCardBg from "images/gameCards/background/canOfSquirms.png";
import colourBrainCardBg from "images/gameCards/background/colourBrain.png";
import drawWithCardBg from "images/gameCards/background/drawWith.png";
import herdMentatlityCardBg from "images/gameCards/background/herdMentatlity.png";
import pubQuizCardBg from "images/gameCards/background/pubQuiz.png";
import scrawlCardBg from "images/gameCards/background/scrawl.png";
import theChameleonCardBg from "images/gameCards/background/theChameleon.png";
import canOfSquirmsLogo from "images/gameCards/logo/canOfSquirms.png";
import colourBrainLogo from "images/gameCards/logo/colourBrain.png";
import drawWithLogo from "images/gameCards/logo/drawWith.png";
import herdMentalityLogo from "images/gameCards/logo/herdMentality.png";
import pubQuizLogo from "images/gameCards/logo/pubQuiz.png";
import scrawlLogo from "images/gameCards/logo/scrawl.png";
import theChameleonLogo from "images/gameCards/logo/theChameleon.png";
import placeholderdesktop from "images/gameStorePreviews/placeholder-desktop.png";
import placeholdermobile from "images/gameStorePreviews/placeholder-mobile.png";



const images = [
lifestyle1,
    lifestyle2,
    lifestyle3,
    canofsquirms,
    colourbrain,
    drawwithdave,
    herdmentality,
    scrawl,
    thechameleon,
    pack1tatos,
    pack2tatos,
    pack1bg,
    pack2bg,
    ChameleonLogo,
    ColourBrainLogo,
    DrawWithLogo,
    HerdLogo,
    PubQuizLogo,
    ScrawlLogo,
    SquirmsLogo,
    woodBg,
    yellowBg,
    yellowBgv2,
    WelcomeGameCityText,
    hourglass,
    canOfSquirmsCardBg,
    colourBrainCardBg,
    drawWithCardBg,
    herdMentatlityCardBg,
    pubQuizCardBg,
    scrawlCardBg,
    theChameleonCardBg,
    canOfSquirmsLogo,
    colourBrainLogo,
    drawWithLogo,
    herdMentalityLogo,
    pubQuizLogo,
    scrawlLogo,
    theChameleonLogo,
    placeholderdesktop,
    placeholdermobile,
];

const fonts = [
    { font: "Bebas Neue", path: `https://${window.location.host}/fonts/BebasNeue.otf` },
    { font: "Futura PT", path: `https://${window.location.host}/fonts/futura-pt-book.ttf` },
];

export default {
    images,
    fonts
};