import ga4 from "./ga4";
import meta from "./meta";
import tiktok from "./tiktok";
import capi from "helpers/capi";

function pageView(path) {
    ga4.ga4PageView(path);
    meta.metaPageView();
    tiktok.tiktokPageView();
}

function addToCart(product, currency, value) {
    console.log("Add To Cart : ", product, currency, value);
    ga4.ga4AddToCart(product, currency, value);
    meta.metaAddToCart(product, currency, value);
    tiktok.tiktokAddToCart(product, currency, value);

    capi.sendEvent("AddToCart", {
        content_ids: [product.productId],
        content_type: "product",
        content_name: product.name,
        value: value,
        currency: currency,
    });


    //window.klaviyo.push(["track", "Added to Cart", {
    //    AddedItemProductID: product.productId,
    //    AddedItemProductName: product.name,
    //    AddedItemPrice: value,
    //}]);

    console.log("klaviyo ", window.klaviyo)
}

function removeFromCart(product, currency, value, quantity = 1) {
    ga4.ga4RemoveFromCart(product, currency, value, quantity);
}

function beginCheckout(products, currency, value, coupon = null) {
    ga4.ga4BeginCheckout(products, currency, value, coupon);
    meta.metaBeginCheckout(products, currency, value);
    tiktok.tiktokBeginCheckout(products, currency, value);

    let num_items = 0;
    products.forEach((product) => {
        num_items += product.quantity;
    });
    capi.sendEvent("InitiateCheckout", {
        content_ids: products.map((product) => product.productId),
        content_type: "product",
        value: value,
        currency: currency,
        num_items,
    });
}

function purchaseComplete(products, currency, value, paymentIntent, coupon = null) {
    ga4.ga4PurchaseComplete(products, currency, value, paymentIntent, coupon);
    meta.metaPurchaseComplete(products, currency, value, paymentIntent);
    tiktok.tiktokPurchaseComplete(products, currency, value, paymentIntent);

    let num_items = 0;
    products.forEach((product) => {
        num_items += product.quantity;
    });
    capi.sendEvent("Purchase", {
        content_ids: products.map((product) => product.productId),
        content_type: "product",
        value: value,
        currency: currency,
        num_items,
    });
}

function login() {
    ga4.ga4Login();
}

function signUp() {
    ga4.ga4SignUp();
    meta.metaSignUp();
    tiktok.tiktokSignUp();
}

export default {
    pageView,
    addToCart,
    removeFromCart,
    beginCheckout,
    purchaseComplete,
    login,
    signUp,
}
