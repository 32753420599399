import React, { Component } from 'react';
import { connect, } from 'react-redux';
import { Accordion, Container, } from 'react-bootstrap';
import { withRouter } from './withRouter';

import styles from "./HelpStyles.module.scss";

import smartTv1 from "images/template/help/smart-tv-1.png";
import laptop1 from "images/template/help/laptop-1.png";
import laptop2 from "images/template/help/laptop-2.png";
import laptop3 from "images/template/help/laptop-3.png";
import laptop4 from "images/template/help/laptop-4.png";
import laptop5 from "images/template/help/laptop-5.png";
import usbC from "images/template/help/usb-c.png";
import cable from "images/template/help/cable.png";
import tv from "images/template/help/tv.png";
import laptop from "images/template/help/laptop.png";

import join1 from "images/template/help/join-1.png";
import join2 from "images/template/help/join-2.png";
import join3 from "images/template/help/join-3.png";
import join4 from "images/template/help/join-4.png";

import wizard from "images/template/help/wizard.png";
import wizardDone from "images/template/help/wizard-done.png";


class Help extends Component {
    static displayName = Help.name;

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <article>

                <section className={`${styles.setupWizardSection} ${styles.band}`}>
                    <Container>

                        <div className={`${styles.intro} ${styles.restrictWidth}`}>
                            <p>Playing a party game on your TV might sound like magic, but don't worry - it's actually quite simple with Game.City</p>
                            <img src={wizard}></img>
                        </div>

                        <div className={`${styles.youWillNeed} ${styles.restrictWidth}`}>
                            <h2>You will need:</h2>

                            <div className={styles.items}>
                                <span className={styles.imageCol}><img src={tv}></img><p>A Smart TV</p></span>
                                <p style={{fontSize: '25px'}}>and/or</p>
                                <span className={styles.imageCol}><img src={laptop}></img><p>A Laptop</p></span>
                                <p style={{fontSize: '25px'}}>+</p>
                                <span className={styles.imageCol}><img src={cable}></img><p>An HDMI Cable</p></span>
                            </div>
                        </div>

                        <div className={`${styles.gameOnTv} ${styles.guide} ${styles.restrictWidth}`}>

                            <h2>1. Getting your game on the big screen</h2>

                            <hr/>

                            <h3>Using a laptop and HDMI cable</h3>

                            <strong className={styles.subHeading}>For this step, you'll need to manually plug your laptop into the back of your TV, Here's what to do:</strong>

                            <ol>
                                <li>
                                    <span className={styles.imageRow}>
                                        <p>Plug one end of the HDMI cable into your laptop.</p>
                                        {/*<img src={laptop1}></img>*/}
                                    </span>
                                </li>
                                <li>
                                    <span className={styles.imageRow}>
                                        <p>Plug the other end of the cable into the HDMI socket in the back (or side) of the TV</p>
                                        {/*<img src={laptop2}></img>*/}
                                    </span>
                                </li>
                                <li>
                                    <span className={styles.imageRow}>
                                        <p>Switch on your TV, Your laptop should now be visible on one of the HDMI channels.</p>
                                        {/*<img src={laptop3}></img>*/}
                                    </span>
                                </li>
                                <li>
                                    <span className={styles.imageRow}>
                                        <p>On your laptop, go to <a href="https://game.city">game.city</a> and select <strong style={{textTransform: 'uppercase'}}>start game</strong></p>
                                        {/*<img src={laptop4}></img>*/}
                                    </span>
                                </li>
                                <li>
                                    <span className={styles.imageRow}>
                                        <p>Type in your registered email and password to start the game.</p>
                                        {/*<img src={laptop5}></img>*/}
                                    </span>
                                </li>
                            </ol>

                            <div className={styles.notice}>
                                <p>If you've already set up your TV with Google Chromecast, or Apple AirPlay, you can use this to mirror your laptop display 
                                (instead of using an HDMI cable)</p>
                            </div>
                            
                            <div className={`${styles.notice} ${styles.imageNotice}`}>
                                <p>Some smaller laptops (especially Apple Macs) may not have an HDMI socket. if this happens, you'll need to purchase a USB-C to HDMI adapter.</p>
                                {/*<img src={usbC}></img>*/}
                            </div>

                            <div className={`${styles.notice} ${styles.imageNotice}`}>
                                <p>You can also play the games through the Web Browser on your Smart TV! <br /> Some TV browsers can cause Game.City Games to run quite slowly, If that happens, try updating your browser - or use the HDMI method.</p>
                                {/*<img src={smartTv1}></img>*/}
                            </div>


                            <hr/>

                            <h2>2. Getting Everyone To Join The Game</h2>

                            <strong className={styles.subHeading}>Now that you're up on the big screen, it's time to get the players involved!</strong>

                            <ol>
                                <li>
                                    <span className={styles.imageRow}>
                                        <p>To join, go to <a href="https://game.city">game.city</a> on your smartphone and select <strong style={{textTransform: 'uppercase'}}>join a game</strong></p>
                                        {/*<img src={join1}></img>*/}
                                    </span>
                                </li>
                                <li>
                                    <span className={styles.imageRow}>
                                        <p>Type in the Room ID, found on the left side of the screen.</p>
                                        {/*<img src={join2}></img>*/}
                                    </span>
                                </li>
                                <li>
                                    <span className={styles.imageRow}>
                                        <p>Make up a nickname, choose a tato, and wait for the other players to join.</p>
                                        {/*<img src={join3}></img>*/}
                                    </span>
                                </li>
                                <li>
                                    <span className={styles.imageRow}>
                                        <p>Pick a game to get started, then do your best to win. Have fun!</p>
                                        {/*<img src={join4}></img>*/}
                                    </span>
                                </li>
                            </ol>

                            <hr/>

                            <h2>You've Done It!</h2>

                            <div className={styles.allDone}>
                                <div>
                                    <p>You're officially ready to play on the big screen!</p>
                                    <p>Good luck, have fun, and don't be too disappointed if you don't win the trophy, there's always next round.</p>
                                    <p>If you run into any problems, please <a href="mailto:info@thegamescollective.com">Get in Touch</a></p>
                                </div>
                                <img src={wizardDone}></img>
                            </div>

                        </div>
                    </Container>
                </section>


                <section className={`${styles.faqSection} ${styles.band}`} id="faqSection" style={{ width: "100%", paddingTop: "40px", paddingBottom: "40px" }}>
                    <div className="container">
                        <div className="row" style={{ marginTop: "20px" }} id="faqrowid">
                            <h1 className='text-center'>Got a burning question?</h1>    
                        </div>
                        <Accordion className={`${styles.restrictWidth} ${styles.faqs}`} style={{ marginBottom: "10px" }} id="faqAccordion">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><h3>DO I NEED TO SIGN IN OR REGISTER TO JOIN A GAME?</h3></Accordion.Header>
                                <Accordion.Body>
                                    <p>Nope! Only the host needs to sign in with their account - all you need to do is 'Join a Game' with the room code your hosts gives you.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header><h3>I'VE BEEN DISCONNECTED FROM THE GAME!</h3></Accordion.Header>
                                <Accordion.Body>
                                    <p>No worries - just go to the join a game page, and re-enter the room code to reconnect!</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header><h3>WE'RE ALL READY, BUT THE GAME WON'T START!</h3></Accordion.Header>
                                <Accordion.Body>
                                    <p>Do you have enough players for that game? Check you have the required amount of players connected before you try start a game! If everything looks right, <a href="mailto:info@thegamescollective.com">Get in Touch</a>!</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header><h3>I CAN'T SIGN IN!</h3></Accordion.Header>
                                <Accordion.Body>
                                    <p>Have you registered your account yet? </p>
                                    <p>Check for typos and make sure you're using the right email address.</p>
                                    <p>Having trouble? <a href="mailto:info@thegamescollective.com">Get in Touch</a></p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header><h3>CAN I HOST THIS ON MY SMART TV?</h3></Accordion.Header>
                                <Accordion.Body>
                                    <p>Game.City works best when hosted from a laptop or desktop computer, as those devices tend to have a faster processing speed. If you want to get this on your Big Screen -  we recommend going old school and hooking your screen up via an HDMI cable.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header><h3>HELP! I'VE LOST MY STEAM KEY(S), HOW DO I RECOVER THEM?</h3></Accordion.Header>
                                <Accordion.Body>
                                    <p>If you bought a Steam key on bigpotato.tv, and need to recover it, <a href="mailto:info@thegamescollective.com">Get in Touch</a> with the email address you used when you purchased it!</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header><h3>MY ACTIVATION CODE SAYS REDEEMED - HOW DO I FIND IT?</h3></Accordion.Header>
                                <Accordion.Body>
                                    <p>You should have recieved an email with your activation code, if you registered after you bought it, it's likely already on your account, Great! Simply login, go to 'Start a Game' and your games should appear. If not, then check the code is being entered correctly. If it's still not working then <a href="mailto:info@thegamescollective.com">Get in Touch</a>.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="7">
                                <Accordion.Header><h3>MY DISCOUNT CODE WON'T WORK?</h3></Accordion.Header>
                                <Accordion.Body>
                                    <p>Check if you have a discount code or a activation code, discounts work only on the check out page and activation codes work only on the <a target="_blank" href="https://game.city/redeem-code">Redeeem Code</a> page!</p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </section>

                <section className={`${styles.band} ${styles.small} ${styles.setupWizardSection}`}>
                    <div className={`${styles.intro} ${styles.restrictWidth}`} style={{justifyContent: 'center'}}>
                        <p>Still having problems?</p>
                        <button className={`${styles.button} ${styles.alternate}`} onClick={() => window.open("https://bigpotato.co.uk/pages/help")}>Get in Touch</button>
                    </div>
                </section>
            </article>
        );
    }
}

export default withRouter(Help);
