import React from "react";
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from "react-redux";

const PrivateRoute = (props) => {
    const { component: Component, ...rest } = props;
    const { isLoggedIn } = useSelector(state => state.auth);

    return (
            isLoggedIn ?
                <Outlet />
                :
            <Navigate to={{ pathname: `/login`, search: props.path ? `?returnURL=${props.path.slice(1)}` : "", }} />
    );
}

export default PrivateRoute;






//import React, { Component } from 'react';
//import { connect, } from 'react-redux';
//import { Route, } from 'react-router-dom';
//import { withRouter } from './withRouter';

//class PrivateRoute extends Component {
//    static displayName = PrivateRoute.name;

//    constructor(props) {
//        super(props);

//        this.state = {
//            loading: false,
//        };
//    }

//    componentWillMount() {

//        if (!this.props.auth.isLoggedIn) {
//            this.props.navigate("/login");
//        }
//    }

//    render() {
//        return (
//            <Route {...this.props.rest} element={this.props.element} key={this.props.key} path={this.props.path} />
//        );
//    }
    
//}
//const mapStateToProps = (state) => {
//    return {
//        auth: state.auth,
//    };
//}

//export default connect(mapStateToProps, null)(withRouter(PrivateRoute));