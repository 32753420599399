import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const getActivePromotion = async () => {
    return await axios.get(API_URL + `api/promotions/get-promotion`);
};

const submitPromotionEntry = async (promotionId, email, country = null, dob = null, tags = []) => {
    const data = {
        PromotionId: promotionId,
        Email: email,
        Country: country,
        DateOfBirth: dob,
        Tags: tags,
    };
    return await axios.post(API_URL + `api/promotions/enter-promotion`, data);
};

export default {
    getActivePromotion,
    submitPromotionEntry,
};