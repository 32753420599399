import {
    UPDATE_PRICING,
    UPDATE_COUNTRY_CODE,
} from "actions/types";


const initialState = {
    //price: "",
    //rawPrice: 0,
    countryCode: null,
    //currency: "",
    //discount: null,
    //discountActive: false,
    //discountPercentage: 0,
    //discountDescription: "",
    //prices: [],
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case UPDATE_PRICING:
            return {
                ...state,
                prices: payload
            };
        case UPDATE_COUNTRY_CODE:
            return {
                ...state,
                countryCode: payload,
            }
        default:
            return state;
    }
}
