import React, { Component } from 'react';
import { connect, } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import AuthService from 'services/auth';
import { withRouter } from '../withRouter';
import styles from '../Forms/FormStyles.module.scss'
import { Modal } from "react-bootstrap";
import GameList from 'constants/GameList';
import GameAddonList from "constants/GameAddonList";
import GamePackList from "constants/GamePackList";
import { setGames } from "actions/auth";

class RedeemCode extends Component {
    static displayName = RedeemCode.name;

    constructor(props) {
        super(props);

        this.state = {
            code: "",
            loading: false,
            message: "",
            error: false,
            codeRedeemed: false,
            missingGames: [],
            showConfirmationModal: false,
        };
    }

    componentWillMount() {

    }


    onChangeCode = (e) => {
        const code = e.target.value;

        this.setState({ code });
    };

    checkVoucher = async (e) => {
        e.preventDefault();

        if (this.state.loading) return;

        this.setState({ loading: true, message: "", error: false, });
        const code = this.state.code;
        const res = await AuthService.checkCode(code);
        if (res.status === "Success") {
            const addon = [...GameList, ...GamePackList, ...GameAddonList].find(a => res.game && a.productId === res.game.stripeProductId);
            const baseGame = GameList.find(g => g.gameId === addon.forGameId);
            const gameMissing = baseGame ? !this.props.auth.user?.games.find(ug => ug.code === baseGame.gameId) : null;
            this.setState({ loading: false, game: addon, missingGame: gameMissing ? baseGame : null, showConfirmationModal: true });
        } else {
            console.log(res.message);
            this.setState({ loading: false, message: res.message, error: true, });
        }
    }

    redeemCode = async (code) => {
        this.setState({ loading: true, game: null, missingGame: null, showConfirmationModal: false });
        AuthService.redeemCode(code).then((response) => {
            const message = response.message || response.message.toString();
            if (response.status === "Error") {
                this.setState({ loading: false, message: message, error: true, code: "", codeRedeemed: true, });
            } else {
                this.props.setGames(response.games);
                this.setState({ loading: false, message: message, code: "", codeRedeemed: true, });
            }
        }, (error) => {
            const messageError = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

            this.setState({ loading: false, message: messageError, error: true, code: "" });
        });
    };

    handleCloseConfirmation = () => {
        this.setState({ showConfirmationModal: false });
    }

    render() {
        return (
            <React.Fragment>
                <Form onSubmit={this.checkVoucher}>

                    <h2 className={styles.formTitle}>Redeem Code</h2>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        {
                            this.state.codeRedeemed === false &&
                            <>
                                <Form.Label>Activation Code</Form.Label>
                                <Form.Control type="text" autoComplete="off" placeholder="Enter code" required onChange={this.onChangeCode} value={this.state.code} />
                            </>
                        }

                        {
                            this.state.message.length > 0 &&
                            <Alert variant={this.state.error ? "danger" : "success"}>
                                {this.state.message}
                            </Alert>
                        }
                        {
                            this.state.codeRedeemed &&
                            <p>Click below to redeem another code, or start playing your games now!</p>
                        }
                    </Form.Group>
                    {
                        this.state.codeRedeemed ?
                            <div className={`${styles.buttonsWrapper} mt-3`}>
                                <button className={styles.button} style={{ marginRight: '10px' }} onClick={() => this.setState({ codeRedeemed: false, message: "", })}>Redeem Another</button>
                                <button className={`${styles.button} ${styles.alternate}`} onClick={() => this.props.navigate("/lobby")}>Start Playing</button>
                            </div>
                            :
                            <button className={styles.button} type="submit">{this.state.loading ? <Spinner animation="border" /> : "Submit"}</button>
                    }
                </Form>
                <Modal show={this.state.showConfirmationModal} onHide={this.handleCloseConfirmation}>
                    <div className={styles.activateModal}>
                        <h2>{this.state.missingGame ? "Missing Base Game" : "Activate"}</h2>
                        {this.state.missingGame && <>
                            <div className={styles.balanced} >{`You are missing this add-on's base game:`}</div>
                            <ul>
                                <li><p className={`${styles.bold} ${styles.link}`} onClick={() => window.open(this.state.missingGame?.storeSlug)} >{this.state.missingGame?.name}</p></li>
                            </ul>
                        </>}
                        <div>Are you sure you want to activate?</div>
                        <ul>
                            <li><p className={`${styles.bold}`}>{this.state.game?.name}</p></li>
                        </ul>
                        {/*{*/}
                        {/*    this.stateactivateMessage.length > 0 &&*/}
                        {/*    <Alert variant={activateStatus == "Success" ? "success" : activateStatus === "Error" ? "danger" : "warning"}>*/}
                        {/*        {activateMessage}*/}
                        {/*    </Alert>*/}
                        {/*}*/}
                        <div className={styles.buttonWrapper}>
                            <button onClick={this.handleCloseConfirmation} className={styles.button}>Close</button>
                            <button onClick={() => this.redeemCode(this.state.code)} className={styles.button}>{this.state.loading ? <Spinner animation="border" /> : "Activate"}</button>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setGames: (games) => {
            dispatch(setGames(games))
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RedeemCode));
