import React from 'react'
import { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { withRouter } from '../withRouter';
import { Alert } from 'react-bootstrap';
import styles from '../Forms/FormStyles.module.scss';
import AuthService from 'services/auth';

class ForgotPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            enterEmail: false,
            input: {},
            errors: {},
            message: '',
            successful: false,
            loading: false,
            isSilk: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.validate = this.validate.bind(this);
        this.handleForgotPassword = this.handleForgotPassword.bind(this);
    }

    componentDidMount() {
        this.setSilk();
    }

    setSilk() {
        //this works to detect silk browser
        if (/\bSilk\b/.test(navigator.userAgent)) {
            this.setState({
                isSilk: true
            });
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        let input = this.state.input;
        input[event.target.name] = event.target.value;

        this.setState({
            [name]: value,
            input
        });
    }

    validate() {
        let input = this.state.input;
        let errors = {};
        let isValid = true;

        if (!input["email"]) {
            isValid = false;
            errors["email"] = "Please enter your email Address.";
        }

        if (typeof input["email"] !== "undefined") {

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(input["email"])) {
                isValid = false;
                errors["email"] = "Please enter valid email address.";
            }
        }

        this.setState({
            errors: errors
        });

        return isValid;
    }

    handleForgotPassword = event => {
        event.preventDefault();

        if (this.validate()) {
            this.setState({
                loading: true
            });

            AuthService.forgotPassword(this.state.email).then((response) => {
                console.log("forgot pw response : ", response);
                let message = response.message || response.message.toString();
                this.setState({
                    message: message,
                    successful: true,
                    loading: false
                });
            }, (error) => {
                console.log("forgot pw error : ", error);
                const messageError = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                this.setState({
                    message: messageError,
                    successful: false,
                    loading: false
                });
            });
        }
    }

    render() {
        return (
            <Form id="forgotPasswordForm" onSubmit={this.handleForgotPassword}>
                <h2 className={styles.formTitle}>Forgot Password</h2>
                <p>Please enter your email so we can find your account and reset your password.</p>
                <Form.Group  controlId="formBasicEmail">
                    <Form.Label>
                        Email
                    </Form.Label>
                    <Form.Control type="email" placeholder="Enter email"
                        type="email"
                        autoComplete="off"
                        id="email"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        required
                        disabled={this.state.successful}
                    />
                    {this.state.errors.email && <Alert variant={"danger"}>{this.state.errors.email}</Alert>}
                </Form.Group>

                {this.state.message && (
                    <Form.Group>
                        <Alert variant={this.state.successful ? "success" : "danger"}>
                            {this.state.message}
                        </Alert>
                    </Form.Group>)}

                {!this.state.successful &&
                    <button className={`${styles.button} mt-3`} type="submit" disabled={this.state.loading}>
                            {this.state.loading && (
                                <Spinner animation="border" />
                            )}
                            Submit
                        </button>
                }
                {this.state.successful &&
                    <Form.Group>
                        <button type="button" onClick={() => this.props.navigate("/login")} className={styles.button}>Login into account</button>
                    </Form.Group>
                }
            </Form>
        )
    }
};

export default withRouter(ForgotPassword);