import {
    UPDATE_PRICING,
    UPDATE_COUNTRY_CODE,
} from "actions/types";
import StripeService from "services/stripe.service";

export const setPricing = () => (dispatch) => {
    return StripeService.getPricing().then(
        (response) => {
            console.log("set pricing response : ", response);

            dispatch({
                type: UPDATE_PRICING,
                payload: response.data,
            });

            return Promise.resolve();
        },
        (error) => {
            const messageError =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            console.log(messageError);

            return Promise.reject();
        }
    );

    
};

export const setCountryCode = () => (dispatch) => {
    return StripeService.getCountryCode().then(
        (response) => {
            dispatch({
                type: UPDATE_COUNTRY_CODE,
                payload: response.data,
            });

            return Promise.resolve();
        },
        (error) => {
            const messageError =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            console.log(messageError);

            return Promise.reject();
        })
}

