import {
    UPDATE_CART,
    UPDATE_TOASTS
} from "actions/types";
import store from 'store/reduxstore';
import { v4 as uuidv4 } from 'uuid';
import Analytics from "analytics";
import GamePackList from "../constants/GamePackList";
import GameList from "../constants/GameList";
import GameAddonList from "../constants/GameAddonList";

export const addToCart = (game) => {
    const currentCart = store.getState().cart.items;
    const currentToasts = addNewToast(game);

    const itemIndex = currentCart.findIndex(x => x.productId === game.productId);
    const singleItemPriceData = getPriceData(game);

    if (itemIndex >= 0) {
        currentCart[itemIndex].quantity++;
        currentCart[itemIndex].total = calculateItemTotal(currentCart[itemIndex].quantity, currentCart[itemIndex].price);
    } else {
        let item = {
            productId: game.productId,
            name: game.name,
            quantity: 1,
            total: singleItemPriceData.raw,
            logo: game.logo,
            minAge: game.gameInfo ? game.gameInfo.minAge : game.gamePackInfo ? game.gamePackInfo.minAge : 0,
            type: game.type,
        };
        currentCart.push(item);
    }

    Analytics.addToCart(game, singleItemPriceData.currency, singleItemPriceData.raw);

    return {
        type: UPDATE_CART,
        payload: {
            items: currentCart,
            toastItems: currentToasts,
        },
    }
};

export const updateCartDetails = () => {
    console.log("Updated cart");
    const currentCart = store.getState().cart.items;
    const currentToasts = store.getState().cart.toastItems;

    const invalidItems = []

    currentCart?.forEach(i => {
        const g = getGame(i.productId);
        if (g) {
            i.total = calculateItemTotal(i.quantity, getPriceData(g).raw);
            i.minAge = g.gameInfo ? g.gameInfo.minAge : g.gamePackInfo ? g.gamePackInfo.minAge : 0;
            i.type = g.type;
        } else {
            invalidItems.push(i);
        }
    });

    //remove invalid items
    invalidItems.forEach(i => currentCart.splice(currentCart.indexOf(i), 1));

    return {
        type: UPDATE_CART,
        payload: {
            items: currentCart,
            toastItems: currentToasts,
        },
    }
}

export const removeFromCart = (game) => {
    const currentCart = store.getState().cart.items;
    const currentToasts = store.getState().cart.toastItems;

    const itemIndex = currentCart.findIndex(x => x.productId === game.productId);

    //let pricingObject = getPricingObject(game.productId);
    let gameData = getGame(game.productId);
    let countryCode = getCountryCode();
    let pricingObject = countryCode === "GB" ? gameData.pricing.gb : gameData.pricing.us;
    Analytics.removeFromCart(game, pricingObject.currency, pricingObject.raw, currentCart[itemIndex].quantity);

    if (itemIndex >= 0) {
        currentCart.splice(itemIndex, 1);
    }


    return {
        type: UPDATE_CART,
        payload: {
            items: currentCart,
            toastItems: currentToasts,
        },
    }
}

export const increaseQuantity = (game) => {
    const currentCart = store.getState().cart.items;
    const currentToasts = store.getState().cart.toastItems;

    const itemIndex = currentCart.findIndex(x => x.productId === game.productId);
    if (itemIndex >= 0) {
        currentCart[itemIndex].quantity++;
        currentCart[itemIndex].total = calculateItemTotal(currentCart[itemIndex].quantity, currentCart[itemIndex].price);
    } 

    //let pricingObject = getPricingObject(game.productId);
    let gameData = getGame(game.productId);
    let countryCode = getCountryCode();
    let pricingObject = countryCode === "GB" ? gameData.pricing.gb : gameData.pricing.us;
    Analytics.addToCart(game, pricingObject.currency, pricingObject.raw);

    return {
        type: UPDATE_CART,
        payload: {
            items: currentCart,
            toastItems: currentToasts,
        },
    }
}

export const lowerQuantity = (game) => {
    const currentCart = store.getState().cart.items;
    const currentToasts = store.getState().cart.toastItems;

    const itemIndex = currentCart.findIndex(x => x.productId === game.productId);
    if (itemIndex >= 0) {
        currentCart[itemIndex].quantity--;
        currentCart[itemIndex].total = calculateItemTotal(currentCart[itemIndex].quantity, currentCart[itemIndex].price);
        if (currentCart[itemIndex].quantity <= 0) {
            currentCart.splice(itemIndex, 1);
        }
    }

    //let pricingObject = getPricingObject(game.productId);
    let gameData = getGame(game.productId);
    let countryCode = getCountryCode();
    let pricingObject = countryCode === "GB" ? gameData.pricing.gb : gameData.pricing.us;
    Analytics.removeFromCart(game, pricingObject.currency, pricingObject.raw);

    return {
        type: UPDATE_CART,
        payload: {
            items: currentCart,
            toastItems: currentToasts,
        },
    }
}


export const emptyCart = () => {
    return {
        type: UPDATE_CART,
        payload: {
            items: [],
            toastItems: [],
        },
    }
}

export const deleteToast = (toastId) => {
    const currentToasts = store.getState().cart.toastItems;

    const itemIndex = currentToasts.findIndex(x => x.id === toastId);
    if (itemIndex >= 0) {
        currentToasts.splice(itemIndex, 1);
    }

    return {
        type: UPDATE_TOASTS,
        payload: {
            toastItems: currentToasts,
        },
    }
}

const calculateItemTotal = (quantity, price) => {
    let itemTotal = 0;
    itemTotal = price * quantity;
    //const formattedItemTotal = round(itemTotal);
    return itemTotal.toFixed(2);
}

function addNewToast(game) {
    const currentToasts = store.getState().cart.toastItems;
    currentToasts.push({
        id: uuidv4(),
        gameId: game.gameId,
        name: game.name,
        price: game.price
    });
    return currentToasts;
}

//function round(num) {
//    var m = Number((Math.abs(num) * 100).toPrecision(15));
//    return Math.round(m) / 100 * Math.sign(num);
//}

//function getPricingObject(productId) {
//    const pricing = store.getState().pricing.prices;
//    let price = pricing.find((x) => x.productId === productId);
//    if (price) {
//        return price;
//    } else {
//        return null;
//    }
//}

function getPriceData(game) {
    return getCountryCode() === "GB" ? game.pricing.gb : game.pricing.us;
}

function getCountryCode() {
    return store.getState().pricing.countryCode;
}

function getGame(productId) {
    let game = [...GameList, ...GamePackList, ...GameAddonList].find((x) => x.productId === productId);
    return game;
}

