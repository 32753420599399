import React, { Component } from 'react';
import { Link, } from 'react-router-dom';

import styles from "components/Layout/FooterStyles.module.scss";
import TrustBoxContainer from '../TrustWidget';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXTwitter, faInstagram, faFacebook, faYoutube, faTiktok } from "@fortawesome/free-brands-svg-icons";

export default class Footer extends Component {

    constructor(props) {
        super(props);

        this.state = {

        };

    }

    getCurrentYear() {
        const date = new Date();
        const year = date.getFullYear();
        return year;
    }

    openInNewTab(url) {
        window.open(url, '_blank').focus();
    }

    render() {
        const pathname = window.location.pathname;
        return (
            !pathname.includes("lobby") &&
            <div className={`container-fluid ${styles.footerContainer}`}>
                {/* Policies */}
                <div className="container">
                    <ul className={`${styles.linksRow}`}>
                        <li className={styles.linkText}>
                            <Link to="/terms">Terms & Conditions</Link>
                        </li>
                        <li className={styles.linkText}>
                            <Link to="/privacy-policy">Privacy Policy</Link>
                        </li>
                        <li className={styles.linkText}>
                            <Link to="/cookie-policy">Cookie Policy</Link>
                        </li>
                        <li className={styles.linkText}>
                            <Link to="/data-protection">Data Protection</Link>
                        </li>
                    </ul>
                    <div className={`${styles.socialsBar}`}>
                        <FontAwesomeIcon icon={faXTwitter} onClick={() => this.openInNewTab("https://x.com/PlayGameCity")} target="_blank" />
                        <FontAwesomeIcon icon={faFacebook} onClick={() => this.openInNewTab("https://www.facebook.com/people/GameCity-Collective/61558537700430/")} target="_blank" />
                        <FontAwesomeIcon icon={faInstagram} onClick={() => this.openInNewTab("https://www.instagram.com/playgame.city/")} target="_blank" />
                        <FontAwesomeIcon icon={faTiktok} onClick={() => this.openInNewTab("https://www.tiktok.com/@gamecityplay")} target="_blank" />
                        <FontAwesomeIcon icon={faYoutube} onClick={() => this.openInNewTab("https://www.youtube.com/channel/UCQ2umAKfPvIbsdzaJvZWaKA")} target="_blank" />
                    </div>
                    {/*<TrustBoxContainer />*/}
                    <div style={{ clear: 'both' }}>
                    </div>
                    <div className={`pt-3 ${styles.copyrightText}`} style={{ textAlign: "center" }}>
                        &copy; {this.getCurrentYear()} The Games Collective, Ltd. All rights reserved.
                    </div>
                </div>
            </div>
        )
    }
}