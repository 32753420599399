export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const ROOM_CREATED = "ROOM_CREATED";
export const ROOM_STATE = "ROOM_STATE";
export const ROOM_ERROR = "ROOM_ERROR";
export const ROOM_LEAVE = "ROOM_LEAVE";
export const ROOM_JOIN = "ROOM_JOIN";
export const ROOM_START_GAME = "ROOM_START_GAME";
export const ROOM_RECONNECT = "ROOM_RECONNECT";
export const ADD_PLAYER = "ADD_PLAYER";
export const REMOVE_PLAYER = "REMOVE_PLAYER";
export const UPDATE_PLAYER = "UPDATE_PLAYER";
export const ADD_AVATAR = "ADD_AVATAR";
export const UPDATE_AVATAR = "UPDATE_AVATAR";

export const UPDATE_CART = "UPDATE_CART";
export const UPDATE_TOASTS = "UPDATE_TOASTS";

export const UPDATE_PRICING = "UPDATE_PRICING";
export const UPDATE_COUNTRY_CODE = "UPDATE_COUNTRY_CODE";

export const SET_DISCOUNT = "SET_DISCOUNT";
export const SET_GAMES = "SET_GAMES";