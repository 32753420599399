import React, { Component } from 'react';

const TrustBox = ({ trustBoxRef }) => (
    <div
        ref={trustBoxRef} // We need a reference to this element to load the TrustBox in componentDidMount.
        className="trustpilot-widget" // Renamed this to className.
        data-locale="en-GB"
        data-template-id="56278e9abfbbba0bdcd568bc"
        data-businessunit-id="653f8fcfe9710b8d04037792"
        data-style-height="52px"
        data-style-width="100%"
    >
        <a
            href="https://uk.trustpilot.com/review/game.city"
            target="_blank"
            rel="noopener noreferrer"
        >
            Trustpilot
        </a>
    </div>
);

class TrustBoxContainer extends Component {
    constructor(props) {
        super(props);
        this.trustBoxRef = React.createRef();
    }
    componentDidMount() {
        // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
        // If it's not, it means the script you pasted into <head /> isn't loaded just yet.
        // When it is, it will automatically load the TrustBox.
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(this.trustBoxRef.current, true);
        }
    }
    render() {
        return <TrustBox trustBoxRef={this.trustBoxRef} />;
    }
}
export default TrustBoxContainer;