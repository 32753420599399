import React, { Component } from 'react';
import { connect, } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { withRouter } from '../withRouter';
import GameList from "constants/GameList";
import { addToCart } from 'actions/cart';

import AddonModal from '../AddonModal/AddonModal';
import styles from './GameAddonStyles.module.scss'

class GameAddon extends Component {
    static displayName = GameAddon.name;

    constructor(props) {
        super(props);

        this.state = {
            game: null,
            baseGame: GameList.find((x) => this.props.addon.forGameId === x.gameId),
        };
    }

    componentDidMount() {

    }


    clickAddToCart(game) {
        if (this.shouldShowAddonModal()) this.setState({ showAddonModal: true });
        else this.props.addToCart(game);
    }

    ownsGame() {
        return this.props.owned;
    }

    getCardStyling() {
        // Bad name of method, this is specific to card background
        return {
            backgroundImage: `url(${this.props.addon.meta.cardBackground})`
        }
    }

    getCardPriceBannerStyling() {
        return {
            background: this.props.addon.meta.cardPriceColour
        }
    }

    getButtonStyling() {
        return {
            background: this.props.addon.meta.storeAccentColour
        }
    }

    clickGameCard = (e) => {
        e.preventDefault();

        if (this.props.fromLobby) {
            this.props.loadGameMenu(this.props.addon);
        } else {
            this.props.navigate(this.props.addon.storeSlug)
        }
    }

    getPrice() {
        return this.props.pricing.countryCode === "GB" ? this.props.addon?.pricing.gb.display : this.props.addon?.pricing.us.display
    }

    shouldShowAddonModal() {
        const ownsBaseGame = !!this.props.auth.user?.games.find(g => g.code === this.state.baseGame.gameId);
        const baseGameIsInCart = !!this.props.cart.items.find(i => i.productId === this.state.baseGame.productId);
        return !(ownsBaseGame || baseGameIsInCart)
    }

    addonModalCallback = () => {
        this.setState({ showAddonModal: false });
    }

    render() {
        return (
            this.props.addon && <>
                {this.state.showAddonModal && <AddonModal ref={this.ref} addon={this.props.addon} baseGame={this.state.baseGame} callback={this.addonModalCallback} ></AddonModal>}
                <div className={`${styles.gameCardWrapper} ${this.props.fromLobby && styles.lobby} ${!this.props.addon.enabled && styles.hidden}`}>
                    <div className={styles.gameCard}>
                        {
                            this.props.addonVotes > 0 &&
                            <div className={styles.votesCounter} style={this.getCardPriceBannerStyling()}><span>{this.props.addonVotes}</span></div>
                        }
                        <div className={`${styles.gameCardBackground} ${this.props.fromLobby && styles.rounded}`} style={this.getCardStyling()}>
                            <img src={this.props.addon.meta.cardLogo} className={`${styles.gameCardLogo} ${this.props.addon.meta.cardLogo2 && styles.hasSecond}`} alt={`${this.props.addon.name} logo`}></img>
                            <div className={styles.addonBanner}>
                                Add-on
                            </div>
                            {this.props.addon.meta.cardLogo2 && <img src={this.props.addon.meta.cardLogo2} className={styles.gameCardLogo2} alt={`${this.props.addon.name} logo`}></img>}
                            {
                                !this.props.fromLobby &&
                                <div className={styles.priceBanner} style={this.getCardPriceBannerStyling()}><span>{this.getPrice() || <Spinner animation="border" size="sm" />}</span></div>
                            }
                            <div onClick={this.clickGameCard} className={styles.clickOverlay} />
                            {
                                this.props.addon.gameInfo.banner &&
                                <div className={styles.banner} style={this.getButtonStyling()}>{this.props.addon.gameInfo.banner}</div>
                            }
                        </div>
                        {
                            !this.props.fromLobby &&
                            <React.Fragment>
                                <div className={`${styles.cardDescriptionBox} p-3`}>
                                    <h4>{this.props.addon.name}</h4>
                                    <p>{this.props.addon.description}</p>
                                </div>
                                <button onClick={() => this.clickAddToCart(this.props.addon)} className={`${styles.addToCartBox} p-3`} disabled={this.props.addon.price === 0}>
                                    <h4>{this.props.addon.price === 0 ? "Auto Redeemed" : "Add To Cart"}</h4>
                                </button>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        room: state.room,
        pricing: state.pricing,
        cart: state.cart,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        addToCart: (game) => {
            dispatch(addToCart(game))
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GameAddon));
