import React, { Component } from 'react';
import { connect, } from 'react-redux';
import axios from 'axios';
import { Container, Spinner, Form, Alert } from 'react-bootstrap';
import { withRouter } from '../withRouter';
import { joinRoom, reconnectToRoom } from 'actions/room';
import * as Sentry from "@sentry/react";
import ErrorModal from '../Utlility/ErrorModal';

// Will need to refactor form styling into forms.scss as its become a bit of a mess
import formStyles from "components/Forms/FormStyles.module.scss"

class Join extends Component {
    static displayName = Join.name;

    constructor(props) {
        super(props);

        this.state = {
            roomId: "",
            sessionId: "",
            loading: false,
            nickname: "",
            errorMessage: "",
            checkingRoomOpened: true,
            connectionError: false,
            canJoin: false,
            joinButtonValue: "JOIN",

            token: "",
        };
        this.cancelToken = null;
    }

    async componentDidMount() {
        // get nickname from local storage
        try {
            const nickname = localStorage.getItem("nickname");
            if (nickname) {
                this.setState({ nickname });
            }
        } catch (e) {
            console.log(e);
        }

        if (this.props.room.roomOpen) {
            if (this.props.room.isHost) {
                return setTimeout(() => this.props.navigate("/lobby"));
            } else {
                return setTimeout(() => this.props.navigate(`/play`));
            }
        } else {
            this.setState({ checkingRoomOpened: false });
            const queryParams = new URLSearchParams(window.location.search);
            if (queryParams.has("token")) { // is attempting reconnect?
                // reconnect here
                const token = queryParams.get("token");

                this.handleReconnect(token);
            } else if (queryParams.has("qrCode")) {
                this.setState({ roomId: queryParams.get("qrCode") });
                await this.handleJoinRoom(queryParams.get("qrCode"));
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.room.roomOpen === true && prevProps.room.roomOpen === false) {
            this.props.navigate(`/play`);
        }
    }

    onChangeRoomId = async (e) => {
        const roomId = e.target.value.toUpperCase();

        if (roomId.length === 4) {
            this.setState({ roomId, });
            await this.checkGameExists(roomId);
        } else {
            this.setState({ canJoin: false, joinButtonValue: "JOIN", roomId });
        }
    };

    onChangeNickname = (e) => {
        const re = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/;
        const nickname = e.target.value.toUpperCase();
        if (nickname === '' || re.test(nickname)) {
            this.setState({ nickname });
        }
    };

    storeSessionId(id) {
        try {
            localStorage.setItem("sessionId", id);
        } catch (e) {
            console.log(e);
        }
        this.setState({ storedSessionId: id });
    }

    getSessionIdFromStorage() {
        try {
            return localStorage.getItem('sessionId');
        } catch (e) {
            console.log(e);
            return null;
        }
    }


    reloadPage = () => {
        window.location.reload(true);
    }


    async checkGameExists(roomId) {
        const storedSessionId = this.getSessionIdFromStorage();

        if (this.cancelToken != null) {
            this.cancelToken.cancel("Operation canceled due to new request.")
        }

        if (roomId != null) {
            this.cancelToken = axios.CancelToken.source();

            return await axios.get(process.env.REACT_APP_MATCH_MAKE_URL + `/game-exists/?roomId=${roomId}&sessionId=${storedSessionId}`,
                {
                    cancelToken: this.cancelToken.token,
                }
            ).then(res => {
                console.log("Game exists check result");
                console.log(JSON.stringify(res));
                let errorMessage = "";

                if (res.data.gameExists) {
                    errorMessage = "";
                    this.setState({ canJoin: true, });
                    if (res.data.playerExists) {
                        this.setState({ joinButtonValue: "Reconnect", errorMessage, });

                        if (res.data.token.length > 0) { 
                            this.setState({ token: res.data.token })
                        }
                    } else {
                        this.setState({ joinButtonValue: "Join", errorMessage, });
                    }
                } else {
                    errorMessage = `Room '${roomId}' does not exist...`;
                    this.setState({ canJoin: false, errorMessage, });
                }
                this.cancelToken = null;
                return res.data;
            }).catch(function (error) {
                if (axios.isCancel(error)) {
                    console.log('Request canceled');
                }
                console.log({ error });

                this.setState({ connectionError: true });

                return null;
            });
        }
    }

    clickJoinRoom = async (e) => {
        e.preventDefault();
        await this.handleJoinRoom();
    }

    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    async handleJoinRoom(override = null) {
        if (this.state.joinButtonValue === "Reconnect") {
            this.handleReconnect(this.state.token);
        } else {

        let roomId = override ? override : this.state.roomId;
        const gameCheckData = await this.checkGameExists(roomId);

        if (!this.state.loading) {
            if (gameCheckData != null && gameCheckData.playerExists) {
                let token = gameCheckData.token;
                this.handleReconnect(token);
            } else {
                this.setState({ loading: true, errorMessage: "", error: false, });

                let options = {};
                let name = this.state.nickname.length > 0 ? this.state.nickname : "PLAYER";
                options.name = name;

                if (name !== "PLAYER") {
                    // store in local storage
                    try {
                        localStorage.setItem("nickname", name);
                    } catch (e) {
                        console.log(e);
                    }
                }

                this.props.joinRoom(roomId, options).then((res) => {
                    if (res.Status === "Error") {
                        this.setState({ loading: false, errorMessage: res.Message, });
                    } else if (res.Status === "Success") {
                        console.log("Join Success");
                        this.storeSessionId(res.room.sessionId);
                    }
                });
            }
            }

        }
    };

    handleReconnect(token) {
        if (!token) {
            this.setState({ errorMessage: "There was a problem reconnecting to the room." })
            return;
        }

        // set roomid to first 4 characters of the token
        let roomId = token.substring(0, 4);

        this.setState({ loading: true, errorMessage: "", error: false, roomId });
        
        setTimeout(() => {
            this.props.reconnectToRoom(token).then((res) => {
                if (res.Status === "Error") {
                    this.setState({ loading: false, errorMessage: res.Message, token: null, joinButtonValue: "Join"})
                } else if (res.Status === "Success") {
                    console.log("res , ", res);
                }
            });
        }, 1500);
    }


    render() {
        //const player = this.props.room.player;
        return (
            <Container>
                {
                    this.state.checkingRoomOpened ?
                        <Spinner animation="border" />
                        :
                        <div>
                            <h2 className={formStyles.formTitle}>Join a Game</h2>
                            <Form onSubmit={this.clickJoinRoom} >
                                <p>To join a room, just enter the Room Code displayed on your hosts device.<br />Or simply scan the QR Code.</p>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Room Code</Form.Label>
                                    <Form.Control type="text" autoComplete="off" maxLength={4} placeholder="Enter Room Id" style={{ textTransform: "uppercase" }} value={this.state.roomId} required onChange={this.onChangeRoomId} />
                                    {
                                        this.state.errorMessage.length > 0 &&
                                        <Alert variant={"danger"}>
                                            {this.state.errorMessage}
                                        </Alert>
                                    }
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Nickname</Form.Label>
                                    <Form.Control type="text" autoComplete="off" placeholder="Enter Nickname" style={{ textTransform: "uppercase" }} value={this.state.nickname} maxLength={10} minLength={2} required={this.state.joinButtonValue !== "Reconnect"} onChange={this.onChangeNickname} />
                                </Form.Group>
                                <button className={formStyles.button} disabled={!this.state.canJoin} variant="primary" type="submit">
                                    {this.state.loading ? <Spinner animation="border" /> : this.state.joinButtonValue}
                                </button>
                            </Form>
                        </div>
                }
                { this.state.connectionError &&
                            <ErrorModal
                    title={"Disconnected"}
                    styles={"d-flex"}
                    message={"There is a problem connecting to the lobby. Please refresh and try again, if the problem persists it may be a firewall blocking your attempts."}
                    callback={this.reloadPage}
                    callbackText={"Try again" }
                            />
                 }
            </Container>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        room: state.room,
    };
}


const mapDispatchToProps = (dispatch) => {
    return {
        joinRoom: (roomId, options) => {
            return dispatch(joinRoom(roomId, options))
        },
        reconnectToRoom: (token) => {
            return dispatch(reconnectToRoom(token))
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Join));
