import React, { Component } from 'react';
import { withRouter } from '../withRouter';
import { getCookie, setCookie } from 'helpers/cookies'
import { connect, } from 'react-redux';
import { addToCart } from 'actions/cart';

import styles from 'components/AddonModal/AddonModalStyles.module.scss';

class AddonModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            enabled: true,
            show: true,
        };
    }

    show() {
        this.setState({ enable: true, show: true });
    }

    componentDidMount() {
    }

    addAddonToCart = () => {
        if (!this.state.show) return;
        this.props.addToCart(this.props.addon);
        this.setState({ show: false });
        setTimeout(() => {
            this.setState({ enabled: false });
            this.props.callback();
        }, 500);
    }

    addBothToCart = () => {
        if (!this.state.show) return;
        this.props.addToCart(this.props.baseGame);
        this.addAddonToCart();
    }

    getPrice(game) {
        return this.props.pricing.countryCode === "GB" ? game?.pricing.gb.display : game?.pricing.us.display;
    }

    render() {
        return <React.Fragment>
            <div className={`${styles.screenOverlay} ${this.state.show && styles.show} ${this.props.styles}`} >
                <div className={styles.screenOverlayBG}></div>
                <div className={styles.modalContainer} >
                    <div className={styles.modal} >
                        <h1 className={styles.title}>Base Game Required!</h1>
                        <div className={`${styles.background} ${styles.columbs}`} >
                            <div className={`${styles.columb} ${styles.center}`}>
                                <div className={styles.priceBanner} style={{ background: this.props.baseGame.meta.cardPriceColour }}><span>{this.getPrice(this.props.baseGame)}</span></div>
                                <img className={styles.gameCard} src={this.props.baseGame.meta.tinyCard} onClick={this.goToBaseGame} alt="required"></img>
                            </div>
                            <div className={`${styles.columb}`}>
                                <div className={`${styles.details}`} >
                                    <h3>Scrawl</h3>
                                    {/*<h3>{this.getPrice(this.props.baseGame)}</h3>*/}
                                    <p>{this.props.baseGame.description}</p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.buttons}>
                            <button onClick={this.addAddonToCart} className={`${styles.button} ${styles.alternate}`} style={{ margin: "0 10px" }}>JUST THE ADD-ON!</button>
                            <button onClick={this.addBothToCart} className={`${styles.button}`} style={{ margin: "0 10px" }}>ADD BOTH TO CART</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        pricing: state.pricing,
        cart: state.cart
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        addToCart: (game) => {
            dispatch(addToCart(game))
        }
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddonModal));