import { combineReducers } from "redux";
import auth from "./auth";
import room from "./room";
import cart from "./cart";
import pricing from "./pricing";
import discount from "./discount";

export default combineReducers({
    auth,
    room,
    cart,
    pricing,
    discount,
});
