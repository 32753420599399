import React, { useState, useEffect } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { Container, Modal, Alert, Spinner, } from "react-bootstrap";
import { emptyCart } from "actions/cart";
import { setGames } from "actions/auth";
import Analytics from "../analytics";
import * as Sentry from "@sentry/react";

import StripeService from "../services/stripe.service";
import AuthService from 'services/auth';

import styles from "./Forms/FormStyles.module.scss";
import Login from "./Forms/Login";
import Register from "./Forms/Register";
import FormPage from "./Forms/FormPage";
import GameList from "../constants/GameList";
import GamePackList from "../constants/GamePackList";
import GameAddonList from "../constants/GameAddonList";
import AddonModal from "./AddonModal/AddonModal";

export default function CheckoutReturn() {
    const [isLoading, setIsLoading] = useState(true);
    const [loadingText, setLoadingText] = useState("Loading...");
    const [purchaseError, setPurchaseError] = useState(false);
    const [products, setProducts] = useState([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [missingGames, setMissingGames] = useState([]);

    const [isActivating, setIsActivating] = useState(false);
    const [productsToActivate, setProductsToActivate] = useState([]);
    const [activateMessage, setActivateMessage] = useState("");
    const [activateStatus, setActivateStatus] = useState("");

    const [showActivate, setShowActivate] = useState(false);
    const [codesApplied, setCodesApplied] = useState(false);

    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showRegisterModal, setShowRegisterModal] = useState(false);

    const { isLoggedIn, user } = useSelector(state => state.auth);
    const { countryCode } = useSelector(state => state.pricing);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const paymentIntentId = new URLSearchParams(window.location.search).get("payment_intent");

        if (!paymentIntentId) {
            navigate("/checkout");
        }
        setLoadingText("Verifying your payment, don't close this page...");
        StripeService.verifyAndGenerateActivationCode(
            paymentIntentId
        ).then((res) => {
            console.log(`verify res : `, res);
            emptyCheckoutCart();
            setIsLoading(false);
            setProducts(res.data.orderProducts);
            assignProductsToActivate(res.data.orderProducts);
            if (!res.data.isFetch) {
                let currency = countryCode === "GB" ? "GBP" : "USD";
                Analytics.purchaseComplete(res.data.orderProducts, currency, res.data.total, paymentIntentId, res.data.coupon);
            }
        }).catch((err) => {
            console.log(`verify err : `, err);
            setPurchaseError(true);
            setIsLoading(false);
            Sentry.captureMessage(`Error verifying payment: ${err}`);
        });
    }, []);

    const emptyCheckoutCart = () => {
        dispatch(emptyCart());
    }


    const assignProductsToActivate = (products) => {
        let listOfCodes = [];
        products.forEach((product) => {
            console.log("product : ", product.codes)
            if (product.codes.length > 1) {
                setShowActivate(true);
            }
            listOfCodes.push(product.codes[0]);
        })
        console.log("list of codes : ", listOfCodes);
        setProductsToActivate(listOfCodes);
    }

    const handleClose = () => {
        if (!isActivating) {
            setActivateMessage("");
            setActivateStatus(false);
            setShowConfirmationModal(false);
        }
    }

    const handleLoginClose = (loggedIn = false) => {
        setShowLoginModal(false);

        if (loggedIn) {
            clickActivate(true);
        }
    }

    const handleRegisterClose = (registered = false) => {
        setShowRegisterModal(false);

        if (registered) {
            console.log("activate codes : ", productsToActivate);
            clickActivate(true);
        }
    }

    const checkMissingBaseGames = () => {
        console.log(products);
        if (productsToActivate.length > 0) {
            if (!isLoggedIn) {
                navigate({
                    pathname: "/login",
                    search: `?${createSearchParams({ returnURL: `checkout-return?payment_intent=${new URLSearchParams(window.location.search).get("payment_intent")}` })}`
                })
            } else {
                const addons = products.map((p) => GameAddonList.find(g => g.productId === p.productId)).filter(x => x);
                const baseGames = addons.map((a) => GameList.find(g => a.forGameId === g.gameId));
                const missingBaseGames = [...new Set(baseGames.filter(bg => !(products.find(p => p.productId === bg.productId) || user?.games.find(ug => ug.code === bg.gameId))))];
                setMissingGames(missingBaseGames);
                setShowConfirmationModal(true);
            }
        }
    }

    const clickActivate = (fromLoginOrRegister = false) => {
        if (productsToActivate.length > 0) {
            if (fromLoginOrRegister) {
                setIsLoading(true);
            } else {
                setIsActivating(true);
            }

            // TO DO: change this to use "redeemMultipleCodes" passing in an array of codes (as strings)
            AuthService.redeemMultipleCodes(productsToActivate).then((response) => {
                if (fromLoginOrRegister) {
                    setIsLoading(false);
                } else {
                    setIsActivating(false);
                }
                let message = response.message || response.message.toString();
                if (response.status == "Info") {
                    response.messageList.forEach((x, i) => {
                        message += `\n`;
                        message += `${x}`;
                    });
                }
                setActivateMessage(message);

                setActivateStatus(response.status);
                if (["Info", "Success"].includes(response.status)) {
                    setShowConfirmationModal(false);
                    setCodesApplied(true);
                    dispatch(setGames(response.games))
                }
            }, (error) => {
                const messageError = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                if (fromLoginOrRegister) {
                    setIsLoading(false);
                } else {
                    setIsActivating(false);
                }
                setActivateMessage(messageError);
                setActivateStatus("Error");
            });
        }
    }

    // Will probably need to remove these once reworked, we will need an object that contains the code within a product object
    let codesToActivate = [];

    // const addOrRemoveCode = (item) => {
    //     const exists = codeExists(item);

    //     if (exists) {
    //         codesToActivate = codesToActivate.filter((c) => { return c !== item })
    //     } else {
    //         codesToActivate.push(item)
    //     }
    //     console.log('Codes to Activate');
    //     console.log(codesToActivate);
    //   }

    // We need to be able to add and remove products to be activated
    // We need to check if the product is already in the array, if it is remove it, if not then add it


    const gameExists = (item, code) => {
        if (productsToActivate.length > 0) {
            console.log(productsToActivate)
            let matchFound = false;
            productsToActivate.forEach(prodCode => {
                if (item.codes.includes(prodCode)) {
                    if (prodCode !== code) matchFound = true;
                }
            })
            return matchFound;
        } else {
            return false;
        }
    }

    const addOrRemoveProductToActivate = (item, code) => {
        let currentProducts = [...productsToActivate]

        const exists = currentProducts.includes(code);

        if (!exists) currentProducts.push(code)

        console.log(item)
        if (exists) currentProducts = currentProducts.filter(prodCode => {
            return prodCode !== code
        })

        setProductsToActivate(currentProducts);
    }

    return (
        <Container>
            {
                isLoading ?
                    <React.Fragment>
                        <Spinner animation="border" />
                        <p>{loadingText}</p>
                    </React.Fragment>
                    :
                    <div>
                        {
                            purchaseError ?
                                <React.Fragment>
                                    <h2 className={styles.formTitle}>Something Went Wrong</h2>
                                    <p>
                                        <Alert variant={"danger"}>
                                            Something went wrong while trying to fetch your codes...
                                        </Alert>
                                    </p>
                                    <p>If you don't recieve an email with your codes, check if you've been charged. Get in Touch if you need support at: <a href="mailto:info@thegamescollective.com">info@thegamescollective.com</a></p>
                                </React.Fragment>
                                :
                            codesApplied ?
                                <React.Fragment>
                                    <h2 className={styles.formTitle}>Games Activated</h2>
                                        <h4 style={{color: "black", marginBottom: "1rem"}}>Your codes have been activated to your account!</h4>
                                    <p>
                                        <Alert variant={activateStatus == "Success" ? "success" : activateStatus == "Error" ? "danger" : "warning"}>
                                            {activateMessage}
                                        </Alert>
                                    </p>
                                    <p>Click below to start playing your new games now!</p>
                                    <button onClick={() => navigate("/lobby")} className={styles.button}>Start Playing</button>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <h2 className={styles.formTitle}>Thanks for your Purchase</h2>
                                    <p>We've sent you an email containing the codes for your games. Or you can add them to your account and start playing now!<br />If you want to activate your games later, just login and go to the 'Redeem Code' page to activate your games!</p>
                                    <table className={styles.productTable}>
                                        <tr>
                                            <th>Game</th>
                                            <th>Code</th>
                                            {
                                                showActivate &&
                                                <th>Activate</th>
                                            }
                                        </tr>
                                        {
                                            products.map((product) => {
                                                return product.codes.map((code) => {
                                                    return <tr className={`${styles.product} ${gameExists(product, code) && styles.disabledProduct}`}>
                                                        <td className={styles.productName}><strong>{product.productName}</strong></td>
                                                        <td className={styles.productCode}>{code}</td>
                                                        {
                                                            showActivate &&
                                                            <td><input checked={productsToActivate.includes(code)} onClick={() => addOrRemoveProductToActivate(product, code)} disabled={gameExists(product, code)} type="checkbox" defaultChecked={false} /></td>
                                                        }
                                                    </tr>
                                                })
                                            })
                                        }
                                    </table>
                                    {
                                        isLoggedIn ?
                                            <React.Fragment>
                                                <p>Want to start playing now? Click below to redeem these codes to your account!</p>
                                                <button onClick={checkMissingBaseGames} className={styles.button}>Add to Account</button>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <p>Want to start playing now? Click below to login or register an account!</p>
                                                <div className={`${styles.buttonsWrapper} mt-3`}>
                                                    <button onClick={() => setShowLoginModal(true)} className={`${styles.button} ${styles.alternate}`} style={{ marginRight: '10px' }}>Login</button>
                                                    <button onClick={() => setShowRegisterModal(true)} className={styles.button}>Register</button>
                                                </div>
                                            </React.Fragment>
                                    }
                                </React.Fragment>
                        }
                    </div>
            }
            <Modal show={showConfirmationModal} onHide={handleClose}>
                <div className={styles.activateModal}>
                    <h2>{missingGames.length > 0 ? "Missing Base Game" : "Activate"}</h2>
                    {missingGames.length > 0 && <div className={styles.balanced} >{`You are missing ${missingGames.length === 1 ? "an" : "multiple"} add-on's base game${missingGames.length === 1 ? "" : "s"}:`}</div>}
                    <ul>
                        {missingGames.map(p => {
                            return <li><p className={`${styles.bold} ${styles.link}`} onClick={() => window.open(p.storeSlug)} >{p.name}</p></li>
                        })}
                    </ul>
                    <div>Are you sure you want to activate these items?</div>
                    <ul>
                        {products.map(p => {
                            return <li><p className={ `${styles.bold}` }>{p.productName}</p></li>
                        })}
                    </ul>
                    {
                        activateMessage.length > 0 &&
                        <Alert variant={activateStatus == "Success" ? "success" : activateStatus === "Error" ? "danger" : "warning"}>
                            {activateMessage}
                        </Alert>
                    }
                    <div className={styles.buttonWrapper}>
                        <button onClick={handleClose} className={styles.button}>Close</button>
                        <button onClick={clickActivate} className={styles.button}>{isActivating ? <Spinner animation="border" /> : "Activate"}</button>
                    </div>
                </div>
            </Modal>
            <Modal show={showLoginModal} onHide={handleLoginClose}>
                <FormPage modal={true}><Login modal={true} closeModal={handleLoginClose} /></FormPage>
            </Modal>
            <Modal show={showRegisterModal} onHide={handleRegisterClose}>
                <FormPage modal={true}><Register modal={true} closeModal={handleRegisterClose} /></FormPage>
            </Modal>
        </Container>
    );
}