import React, { Component } from 'react';
import { connect, } from 'react-redux';
import { Container, Spinner, Row, Col, Form, } from 'react-bootstrap';
import Lottie from "react-lottie";
import { withRouter } from '../withRouter';
import { joinRoom, reconnectToRoom } from 'actions/room';
import { BiLeftArrowAlt } from "react-icons/bi";
import * as Sentry from "@sentry/react";

import styles from "components/Room/Play.module.scss";

import getAvatarById from "constants/avatars";
import GameList from "constants/GameList";

import GameMenu from "./GameMenu";

import GameVote from "../Games/GameVote"
// Will need to refactor form styling into forms.scss as its become a bit of a mess

class Play extends Component {
    static displayName = Play.name;

    constructor(props) {
        super(props);

        this.state = {
            roomId: "",
            sessionId: "",
            nickname: "",
            showAvatars: false,
            chosenAvatar: 0,
            player: null,
            selectedGame: null,

            gotLocationPing: true,
            connectionIssue: false,
        };
    }

    componentDidMount() {
        if (this.props.room.roomOpen) {
            if (this.props.room.isHost) {
                return setTimeout(() => this.props.navigate("/lobby"));
            } else {
                this.setRoomVars();
            }
        } else {
            const queryParams = new URLSearchParams(window.location.search);
            if (queryParams.has("qrCode")) {
                return setTimeout(() => this.props.navigate(`/join?qrCode=${queryParams.get("qrCode")}`));
            } else if (queryParams.has("token")) {
                this.props.reconnectToRoom(queryParams.get("token")).then((res) => {
                    if (res.Status == "Error") {
                        return setTimeout(() => this.props.navigate("/join"));
                    }
                });
            } else {
                return setTimeout(() => this.props.navigate("/join"));
            }
        }
    }

    setRoomVars() {
        this.setState({
            roomId: this.props.room.roomId,
            sessionId: this.props.room.sessionId,
        });
        this.setupPlayer(this.props.room.roomState, this.props.room.sessionId);
        this.updateToken(this.props.room.room.reconnectionToken);
        this.setTags();
        this.startLocationChecks();
    }

    updateToken(token) {
        var url = new URL(window.location.href);

        try {
            window.history.replaceState(null, null, (url.pathname) + (`?token=${token}`));
        } catch (e) {
            console.warn(e)
        }
    }

    setTags() {
        const queryParams = new URLSearchParams(window.location.search);

        const token = queryParams.get("token");
        Sentry.setTag('isPlayer', true);

        if (token) {
            const [roomId, reconnectToken] = token.split(':');
            Sentry.setTag('roomId', roomId);
            Sentry.setTag('reconnectToken', reconnectToken);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.room.roomOpen == false && prevProps.room.roomOpen == true) {
            this.props.navigate("/join");
        } else if (this.props.room.roomOpen == true && prevProps.room.roomOpen == false) {
            this.setRoomVars();
        }
    }

    onChangeNickname = (e) => {
        const re = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/;
        const nickname = e.target.value.toUpperCase();
        if (nickname === '' || re.test(nickname)) {
            this.setState({ nickname });
        }
    };

    setupPlayer(state, sessionId) {
        this.setState({ player: state.players[sessionId] });

        state.players[sessionId].onChange = (changes) => {
            let statePlayer = { ...this.state.player };
            changes.forEach(change => {
                //console.log(change.field);
                //console.log(change.value);
                //console.log(change.previousValue);
                statePlayer[change.field] = change.value;
            });
            this.setState({ player: statePlayer });
        };
    }

    startLocationChecks() {

        if (this.props.room.roomOpen) {
            this.props.room.room.onMessage("location_confirmed", (message) => {
                console.log("location_confirmed", "received on", this.props.room.room.name, message);
                this.setState({ gotLocationPing: true, });
            });

            this.props.room.room.send("location_check", { gameId: "lobby", });
        }
        this.locationCheckInterval = setInterval(() => {
            if (this.props.room.roomOpen) {
                if (this.state.gotLocationPing) {
                    this.setState({ gotLocationPing: false, connectionIssue: false, });
                } else {
                    this.setState({ connectionIssue: true, });
                }
                this.props.room.room.send("location_check", { gameId: "lobby", });
            }
        }, 10000);
    }

    getAvatarList() {
        let arr = [];
        const currentAvatar = this.state.player.avatar;
        this.props.room.avatarList.forEach((value, key) => {
            if (value.isActive) {
                arr.push(<Col className={styles.col}>
                    <div className={`${styles.avatar} ${this.state.chosenAvatar == value.id && !value.inUse && styles.selected} ${currentAvatar == value.id && styles.applied}`}
                        onClick={() => this.chooseAvatar(value.id)}
                    >
                        {
                            value.inUse && currentAvatar != value.id ?
                                <img className={`${styles.potato} ${styles.fade}`} src={getAvatarById(value.id).src} />
                                :
                                <Lottie
                                    options={getAvatarById(value.id).idleAnim}
                                    width="100%"
                                    height="100%"
                                    isClickToPauseDisabled={true}
                                />
                        }
                    </div>
                </Col>);
            }
        });
        return arr;
    }

    chooseAvatar(id) {
        this.setState({ chosenAvatar: id });
        this.props.room.room.send("update_avatar", { avatarId: id, });
    }

    applyNameAndAvatar = (e) => {
        e.preventDefault();

        window.scrollTo(0, 0);
        this.setState({ showAvatars: false });
        this.props.room.room.send("update_avatar", { avatarId: this.state.chosenAvatar, nickName: this.state.nickname, });
    }


    voteForGame = (game) => {
        console.log("voting for game : ", game.gameId);
        this.props.room.room.send("game_vote", { gameId: game.gameId });
    }

    loadGameMenu = (game) => {
        this.setState({ selectedGame: game });
    }

    closeGameMenu = () => {
        this.setState({ selectedGame: null });
    }

    leaveRoom = (id) => {
        this.props.room.room.leave(true);
    }

    showAvatars = () => {
        window.scrollTo(0, 0);
        this.setState({ showAvatars: true, nickname: this.state.player.name, });
    }

    render() {
        //const player = this.props.room.player;
        return (
            <div className={styles.pageWrap}>
                <Container>
                    {
                        this.state.player != null && this.props.room.roomOpen ?
                            <Container className="text-center position-relative" style={{ maxWidth: "600px" }}>
                                {
                                    this.state.connectionIssue &&
                                    <div className={styles.connectionIssueContainer}>
                                        <div className={styles.connectionText}>There might be an issue with your connection...<br />Click below to refresh!</div>
                                        <div className={styles.refreshButton} onClick={() => window.location.reload()}>&#x21bb;</div>
                                    </div>
                                }
                                {
                                    this.state.showAvatars ?
                                        <React.Fragment>
                                            <Row>
                                                <h2>Pick your avatar</h2>
                                            </Row>
                                            <Row className={`${styles.avatarList} row-cols-4`}>
                                                {
                                                    this.getAvatarList()
                                                }
                                            </Row>
                                            <Row>
                                                <Form className="d-flex flex-column" onSubmit={this.applyNameAndAvatar}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label className={styles.nickName}>Nickname</Form.Label>
                                                        <Form.Control type="text" autoComplete="off" required value={this.state.nickname.toLowerCase() === "player" ? "" : this.state.nickname} placeholder="Enter Nickname" style={{ textTransform: "uppercase" }} maxLength={10} minLength={2} required onChange={this.onChangeNickname} />
                                                    </Form.Group>

                                                    <button type="submit" className={styles.button} >Confirm</button>
                                                </Form>
                                            </Row>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>

                                            <div className={styles.topSection}>
                                                <div className={styles.pickedTato}>
                                                    <div className={styles.tato}>
                                                        <Lottie
                                                            options={getAvatarById(this.state.player.avatar).idleAnim}
                                                            width="100%"
                                                            height="100%"
                                                            isClickToPauseDisabled={true}
                                                        />
                                                    </div>
                                                    <p className={`${this.state.player.name.length >= 6 ? this.state.player.name.length >= 9 ? styles.smaller : styles.small : ""}`}>{this.state.player.name}</p>
                                                </div>

                                                <button className={styles.button} onClick={() => this.showAvatars()}>Change</button>
                                            </div>

                                            {
                                                this.state.player.primaryPlayer ?
                                                    <React.Fragment>
                                                        <Row>
                                                            <h2>Pick a Game to Play</h2>
                                                            <p>You're the VIP! You can start a game instead of voting!</p>
                                                        </Row>
                                                        <Row className={styles.voteForGames}>
                                                            {
                                                                this.props.room.roomState.availableGames.map((x, i) => {
                                                                    const g = GameList.find((y) => y.gameId == x);
                                                                    if (!g) return null;
                                                                    return <Col md={6} sm={6} xs={6} style={{ padding: "1vh" }}><GameVote game={g} clickEvent={this.loadGameMenu} showVotes={true} /></Col>
                                                                })
                                                            }
                                                        </Row>
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <Row>
                                                            <h2>Vote for A Game</h2>
                                                            <p>Vote for the game you want to play!</p>
                                                        </Row>
                                                        <Row className={styles.voteForGames}>
                                                            {
                                                                this.props.room.roomState.availableGames.map((x, i) => {
                                                                    const g = GameList.find((y) => y.gameId == x);
                                                                    if (!g) return null;
                                                                    return <Col md={6} sm={6} xs={6} style={{padding: "1vh"}}><GameVote game={g} selected={this.state.player.gameVote == x} clickEvent={this.voteForGame} /></Col>
                                                                })
                                                            }
                                                        </Row>
                                                    </React.Fragment>
                                            }

                                        </React.Fragment>
                                }
                                {
                                    this.state.selectedGame &&
                                    <GameMenu game={this.state.selectedGame} closeGameMenu={this.closeGameMenu} isMobile={true} />
                                }
                                <button onClick={this.leaveRoom} className={`${styles.button} ${styles.alternate} ${styles.small}`}><BiLeftArrowAlt />Leave</button>
                            </Container>
                            :
                            <Container style={{ justifyContent: "center", alignItems: "center", padding: "5vh", display: "flex" }}>
                                <Spinner animation="border" />
                            </Container>
                    }
                </Container>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        room: state.room,
    };
}


const mapDispatchToProps = (dispatch) => {
    return {
        joinRoom: (roomId, options) => {
            return dispatch(joinRoom(roomId, options))
        },
        reconnectToRoom: (token) => {
            return dispatch(reconnectToRoom(token))
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Play));
