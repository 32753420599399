import React from "react";
import { Link, } from "react-router-dom";

const PolicyLinks = () => {
    return (
        <div align="center" style={{ marginBottom: "20px" }}>
            <div>
                <Link to="/">Home</Link>
            </div>
            <div>
                <Link to="/terms">Terms & Conditions</Link>
            </div>
            <div>
                <Link to="/privacy-policy">Privacy Policy</Link>
            </div>
            <div>
                <Link to="/cookie-policy">Cookie Policy</Link>
            </div>
            <div>
                <Link to="/data-protection">Data Protection</Link>
            </div>
            {/*<div>*/}
            {/*    <Link to="/jubilant-june">Jubilant June</Link>*/}
            {/*</div>*/}
            <div>
                <Link to="/fri-yay-giveaway">Fri-Yay Givaway</Link>
            </div>
        </div>
    );
}

export default PolicyLinks;