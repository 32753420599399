/* eslint-disable import/no-anonymous-default-export */
import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_GAMES,
} from "actions/types";

const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
        return null;
    }
};

let initialLoggedInState = false;

let user = JSON.parse(localStorage.getItem("user")) || null;

if (user) {
    const decodedJwt = parseJwt(user.token);
    if (decodedJwt.exp * 1000 > Date.now()) {
        initialLoggedInState = true;
    } else {
        user = null;
    }
}

const initialState = { isLoggedIn: initialLoggedInState, user };

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case REGISTER_SUCCESS:
            return {
                ...state,
                isLoggedIn: false,
            };
        case REGISTER_FAIL:
            return {
                ...state,
                isLoggedIn: false,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: payload.user,
            };
        case LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
            };
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
            };
        case SET_GAMES:
            return {
                ...state,
                user: payload
            };
        default:
            return state;
    }
}
