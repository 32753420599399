import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import StripeService from "../services/stripe.service";
import GameList from "../constants/GameList";
import GamePackList from "constants/GamePackList";
import GameAddonList from "constants/GameAddonList";
import { setDiscount, } from "actions/discount";

import styles from "./DiscountReaderStyles.module.scss";


export default function DiscountReader() {
    const [promoCodeValid, setPromoCodeValid] = useState(false);
    const [promoCodeMessage, setPromoCodeMessage] = useState("");

    const { items, } = useSelector(state => state.cart);

    const dispatch = useDispatch();

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.has("discountCode")) {
            checkPromotionCode(queryParams.get("discountCode"));
        }
    }, []);

    const checkPromotionCode = (promoCode) => {
        console.log("Checking Promo Code : ", promoCode);
        if (promoCode.length > 0) {
            const productIds = [...GamePackList, ...GameList, ...GameAddonList].map((x) => x.productId);

            StripeService.checkPromotionCode(promoCode, productIds)
                .then((res) => {
                    console.log("Check promo code res: ", res);
                    if (res.data.valid === true) {
                        if (res.data.applicableProductIds.length > 0) {
                            const applicableProducts = [...GamePackList, ...GameList, ...GameAddonList].filter((x) => res.data.applicableProductIds.includes(x.productId));
                            const applicableProductNames = applicableProducts.map((x) => x.name);

                            setPromoCodeMessage(`Your discount code '${promoCode}' for ${res.data.percentOff}% off, will be applied at checkout for: ${applicableProductNames.join(", ")}!`);

                        } else {
                            setPromoCodeMessage(`Your discount code '${promoCode}' for ${res.data.percentOff}% off, will be applied at checkout!`);
                        }
                        setPromoCodeValid(true);
                        dispatch(setDiscount(promoCode));
                    }
                }).catch((err) => {
                    console.log("Check promo code err: ", err);
                    setPromoCodeValid(false);
                    setPromoCodeMessage("");
                });
        }
    }

    return (
            promoCodeValid &&
            <header className={styles.appNotification}>{promoCodeMessage}</header>
    );
}