const reviews = [
    {
        name: "ShineyCat.com",
        stars: 5,
        description: "Definitely worth getting if you play party games of ANY kind!"
    },
    {
        name: "TechRaptor",
        stars: 5,
        description: "Tons of fun and flexibility."
    },
    {
        name: "@BoredBoardGames",
        stars: 5,
        description: "For Jackbox lovers, you have to try Big Potato TV"
    },
    {
        name: "Hamo789",
        stars: 4,
        description: "An excellent family friendly game."
    },
    {
        name: "Tdog R.",
        stars: 5,
        description: "This is my new favourite game in the world! it would blow the other ones into ablivieons"
    },
    {
        name: "Noel K.",
        stars: 4,
        description: "Scrawl is brilliant! It would be much better if there was a bigger selection of games. Also, the room connection is quite hot and miss, even with good WiFi connection"
    },
    {
        name: "India M.",
        stars: 5,
        description: "plssss release more games to add on :) we love it"
    },
    {
        name: "Gemma L.",
        stars: 5,
        description: "Bought to play with the family over the Christmas period and it was a huge hit! I like to think I'm quiet good with tech and it did prove quite difficult for everyone to join the room first go, it was slightly infuriating when players would disconnect from the game for no reason and joining back in would then open another player account instead of just reloading back into the account already playing but, we got there in the end and fun was had all round. Our favourite is definitely Scrawl, it's amazing! Hopefully there can be more games added in the future, that would be good!"
    },
    {
        name: "Julie E.",
        stars: 5,
        description: "We love this , so much fun!! Please Please Please update it as we are just getting repeat questions and drawings (scrawl) we also have the physical games of scrawl and Herd Mentality do we know there are plenty more questions/drawings that can go on. We don�t play anymore as we are getting repeat after repeat. Different games would be good, we would pay for extra to �add on �"
    },
    {
        name: "Charlotte B.",
        stars: 5,
        description: "Really good run, would be great to have more games!"
    },
    {
        name: "Philip S.",
        stars: 5,
        description: "Great family fun! Kids love it!"
    },
    {
        name: "Tesni W.",
        stars: 4,
        description: "My husband caught covid on the 21st of December and so had to isolate whilst I went to his parents for Christmas. It was amazing to still be able to play games with him (over a video chat)! Really helped make Christmas feel as \"normal\" as it could be! It will definitely come in handy in the future as well - really excited to see if more games get added!"
    },
];

export default reviews;